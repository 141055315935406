import React from "react";
import GetInTouchSection from "../AboutPages/AboutOne/GetInTouchSection";
import Hero2 from "../HomeThree/Hero2";
import TestimonialSection from "../HomeThree/TestimonialSection";
import BacktoTopCom from "./BackToTopCom";
import Layouts from "./Layouts";

export default function InnerPageDefaultLayoutAbout({
  children,
  pageTitle,
  breadcrumbs = [],
}) {
  return (
    <Layouts pageTitle={pageTitle} breadcrumbs={breadcrumbs}>
      <Hero2 />
      {children && children}
      <TestimonialSection />
      <GetInTouchSection />
      <BacktoTopCom />
    </Layouts>
  );
}
