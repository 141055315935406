import React, { useEffect, useState } from "react";

// eslint-disable-next-line no-unused-vars
export default function Hero4() {
  const tab = <>&nbsp;&nbsp;</>;
  const [dataTitleServicesDetails1, setData107] = useState("");
  const [dataTitleServicesDetails6, setData108] = useState("");
  const [dataTitleServicesDetails7, setData109] = useState("");

  // const [dataContact, setData3] = useState("");

  // fetch(process.env.REACT_APP_API_NADVAR, {
  //   method: "GET",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // })
  //   .then((response) => response.json())
  //   .then((data) => {
  //     setData3(data.data[0].attributes.contact);
  //   });

  useEffect(() => {
    consultThree();
  }, [localStorage.getItem("id_language")]);

  const consultThree = () => {
    fetch(
      `${
        process.env.REACT_APP_API
      }/ds-pro-services-details/${localStorage.getItem("id_language")}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData107(data.data.attributes.title_servicesdetails_1);
        setData108(data.data.attributes.title_servicesdetails_6);
        setData109(data.data.attributes.title_servicesdetails_7);
        // setData3(data.data[0].attributes.contact);
      });
  };

  return (
    <section
      className="hero-section-two bgs-cover overlay pt-40 rpt-80"
      // eslint-disable-next-line no-useless-concat
      style={{
        backgroundImage:
          // eslint-disable-next-line prefer-template
          `url(${process.env.REACT_APP_API_SERVICES_DETAILS_BACKGROUND})`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-10 col-sm-11">
            <div className=" text-white py-150 rpy-150">
              <div className="testimonial-two-active">
                <br /> <br />
                <h1 className="wow fadeInUp delay-0-4s mt-30">
                  {dataTitleServicesDetails1}
                </h1>
                <div className="terms">
                  <h3 className="wow fadeInUp delay-0-4s mt-20">
                    <a href="/home">
                      {" "}
                      {dataTitleServicesDetails6}
                      {tab}
                    </a>
                  </h3>

                  <h3 className="wow fadeInUp delay-0-4s mt-20"> / </h3>

                  <h3 className="wow fadeInUp delay-0-4s mt-20">
                    <a href="/service-details">
                      {tab}
                      {dataTitleServicesDetails7}
                    </a>
                  </h3>
                </div>
              </div>
              {/* <div className="hero-btns mt-35 wow fadeInUp delay-0-6s">
                <a href="/contact" className="theme-btn mr-25">
                  {dataContact}
                </a>
                <a
                  onClick={(e) => videoHandler(e)}
                  href="#"
                  className="mfp-iframe video-play"
                >
                  <i className="fas fa-play"></i>
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
