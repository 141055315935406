import React from "react";
import InnerPageDefaultLayoutProjects from "../../helpers/InnerPageDefaultLayoutProjects";
import MainSection from "./MainSection";

export default function PortfolioThree() {
  return (
    <InnerPageDefaultLayoutProjects
      pageTitle="Portfolio Three"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Portfolio Three", path: "/portfolio-three" },
      ]}
    >
      <MainSection />
    </InnerPageDefaultLayoutProjects>
  );
}
