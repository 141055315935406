import React from "react";
// import GetInTouchSection from "../AboutPages/AboutOne/GetInTouchSection";
import Hero3 from "../HomeThree/Hero3";
// import TestimonialSection from "../HomeThree/TestimonialSection";
// import BacktoTopCom from "./BackToTopCom";
import Layouts from "./Layouts";

export default function InnerPageDefaultLayoutServices({ children }) {
  return (
    <Layouts>
      <Hero3 />
      {children && children}
      {/* <TestimonialSection />
      <GetInTouchSection />
      <BacktoTopCom /> */}
    </Layouts>
  );
}
