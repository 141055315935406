import React from "react";
// import GetInTouchSection from "../AboutPages/AboutOne/GetInTouchSection";
import Hero4 from "../HomeThree/Hero4";
// import TestimonialSection from "../HomeThree/TestimonialSection";
// import BacktoTopCom from "./BackToTopCom";
import Layouts from "./Layouts";

export default function InnerPageDefaultLayoutServicesDetails({ children }) {
  return (
    <Layouts>
      <Hero4 />
      {children && children}
      {/* <TestimonialSection /> */}
      {/* <GetInTouchSection />
      <BacktoTopCom /> */}
    </Layouts>
  );
}
