import React, { useEffect, useState } from "react";
import {
  AiOutlineCloudServer,
  AiOutlineFundProjectionScreen,
  AiOutlineSelect,
} from "react-icons/ai";
import { BsCurrencyBitcoin } from "react-icons/bs";
import { GiProgression } from "react-icons/gi";
import { MdDeveloperMode } from "react-icons/md";
import { TbCloudComputing } from "react-icons/tb";
import { VscFileCode } from "react-icons/vsc";

function ServiceSection() {
  // const [dataTitleServices1, setData60] = useState("");
  const [dataTitleServicesCard1, setData61] = useState("");
  const [dataTitleServicesCard2, setData62] = useState("");
  const [dataTitleServicesCard3, setData63] = useState("");
  const [dataTitleServicesCard4, setData64] = useState("");
  const [dataTitleServicesCard5, setData65] = useState("");
  const [dataTitleServicesCard6, setData66] = useState("");
  const [dataTitleServicesCard7, setData67] = useState("");
  const [dataTitleServicesCard8, setData68] = useState("");
  const [dataTitleServicesCard9, setData69] = useState("");
  const [dataTitleServicesCard10, setData70] = useState("");
  const [dataTitleServicesCard11, setData71] = useState("");
  const [dataTitleServicesCard12, setData72] = useState("");
  const [dataTitleServicesCard13, setData73] = useState("");
  const [dataTitleServicesCard14, setData74] = useState("");
  const [dataTitleServicesCard15, setData75] = useState("");
  const [dataTitleServicesCard16, setData76] = useState("");
  const [dataContentServicesCard1, setData77] = useState("");
  const [dataContentServicesCard2, setData78] = useState("");
  const [dataContentServicesCard3, setData79] = useState("");
  const [dataContentServicesCard4, setData80] = useState("");
  const [dataContentServicesCard5, setData81] = useState("");
  const [dataContentServicesCard6, setData82] = useState("");
  const [dataContentServicesCard7, setData83] = useState("");
  const [dataContentServicesCard8, setData84] = useState("");
  const [dataContentServicesCard9, setData85] = useState("");
  const [dataContentServicesCard10, setData86] = useState("");
  const [dataContentServicesCard11, setData87] = useState("");
  const [dataContentServicesCard12, setData88] = useState("");
  const [dataContentServicesCard13, setData89] = useState("");
  const [dataContentServicesCard14, setData90] = useState("");
  const [dataContentServicesCard15, setData91] = useState("");
  const [dataContentServicesCard16, setData92] = useState("");
  // const [dataReadMore1, setData93] = useState("");
  // const [dataReadMore2, setData94] = useState("");
  // const [dataReadMore3, setData95] = useState("");
  // const [dataReadMore4, setData96] = useState("");
  // const [dataReadMore5, setData97] = useState("");
  // const [dataReadMore6, setData98] = useState("");
  // const [dataReadMore7, setData99] = useState("");
  // const [dataReadMore8, setData100] = useState("");
  // // const [dataTitleServices2, setData101] = useState("");
  // // const [dataTitleServices3, setData102] = useState("");

  useEffect(() => {
    consultThree();
  }, [localStorage.getItem("id_language")]);

  const consultThree = () => {
    fetch(
      `${process.env.REACT_APP_API}/ds-pro-services/${localStorage.getItem(
        "id_language"
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // setData60(data.data[0].attributes.title_services_1);
        setData61(data.data.attributes.title_services_card_1);
        setData62(data.data.attributes.title_services_card_2);
        setData63(data.data.attributes.title_services_card_3);
        setData64(data.data.attributes.title_services_card_4);
        setData65(data.data.attributes.title_services_card_5);
        setData66(data.data.attributes.title_services_card_6);
        setData67(data.data.attributes.title_services_card_7);
        setData68(data.data.attributes.title_services_card_8);
        setData69(data.data.attributes.title_services_card_9);
        setData70(data.data.attributes.title_services_card_10);
        setData71(data.data.attributes.title_services_card_11);
        setData72(data.data.attributes.title_services_card_12);
        setData73(data.data.attributes.title_services_card_13);
        setData74(data.data.attributes.title_services_card_14);
        setData75(data.data.attributes.title_services_card_15);
        setData76(data.data.attributes.title_services_card_16);
        setData77(data.data.attributes.content_services_card_1);
        setData78(data.data.attributes.content_services_card_2);
        setData79(data.data.attributes.content_services_card_3);
        setData80(data.data.attributes.content_services_card_4);
        setData81(data.data.attributes.content_services_card_5);
        setData82(data.data.attributes.content_services_card_6);
        setData83(data.data.attributes.content_services_card_7);
        setData84(data.data.attributes.content_services_card_8);
        setData85(data.data.attributes.content_services_card_9);
        setData86(data.data.attributes.content_services_card_10);
        setData87(data.data.attributes.content_services_card_11);
        setData88(data.data.attributes.content_services_card_12);
        setData89(data.data.attributes.content_services_card_13);
        setData90(data.data.attributes.content_services_card_14);
        setData91(data.data.attributes.content_services_card_15);
        setData92(data.data.attributes.content_services_card_16);
        // setData93(data.data.attributes.read_more_services_1);
        // setData94(data.data.attributes.read_more_services_2);
        // setData95(data.data.attributes.read_more_services_3);
        // setData96(data.data.attributes.read_more_services_4);
        // setData97(data.data.attributes.read_more_services_5);
        // setData98(data.data.attributes.read_more_services_6);
        // setData99(data.data.attributes.read_more_services_7);
        // setData100(data.data.attributes.read_more_services_8);
        //     setData101(data.data[0].attributes.title_services_2);
        //     setData102(data.data[0].attributes.title_services_3);
      });
  };

  return (
    <section className="services-section pt-120 rpt-100 pb-90 rpb-70 containertyle5 center4">
      <div className="container">
        <div className="row">
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-2s">
              <div className="service-normal">
                <div className="icon center2 ">
                  <i className="marginstops14">
                    <AiOutlineFundProjectionScreen>
                      {" "}
                    </AiOutlineFundProjectionScreen>
                  </i>
                </div>
                <h4 className="text-white fontelection10">
                  {dataTitleServicesCard1}
                </h4>
                <p className="text-white fontelection11">
                  {dataContentServicesCard1}
                </p>
                <a className="btn-circle" href="/service-details">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-blue ">
                <h3>{dataTitleServicesCard9}</h3>
                <p className="fontelection12">{dataContentServicesCard9}</p>
                {/* <a href="/service-details" className="theme-btn text-white">
                  {dataReadMore1}
                </a> */}
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-4s">
              <div className="service-normal">
                <div className="icon center2">
                  <i className="marginstops14">
                    <AiOutlineCloudServer> </AiOutlineCloudServer>
                  </i>
                </div>
                <h4 className="text-white fontelection10">
                  {dataTitleServicesCard2}
                </h4>
                <p className="text-white fontelection11">
                  {dataContentServicesCard2}
                </p>
                <a className="btn-circle" href="/service-details">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-blue ">
                <h3>{dataTitleServicesCard10}</h3>
                <p className="fontelection12">{dataContentServicesCard10}</p>
                {/* <a href="/service-details" className="theme-btn text-white">
                  {dataReadMore2}
                </a> */}
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-4s">
              <div className="service-normal">
                <div className="icon center2">
                  <i className="marginstops14">
                    <AiOutlineSelect> </AiOutlineSelect>
                  </i>
                </div>
                <h4 className="text-white fontelection10">
                  {dataTitleServicesCard3}
                </h4>
                <p className="text-white fontelection11">
                  {dataContentServicesCard3}
                </p>
                <a className="btn-circle" href="/service-details">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-blue ">
                <h3>{dataTitleServicesCard11}</h3>
                <p className="fontelection12">{dataContentServicesCard11}</p>
                {/* <a href="/service-details" className="theme-btn text-white">
                  {dataReadMore3}
                </a> */}
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-4s">
              <div className="service-normal">
                <div className="icon center2">
                  <i className="marginstops14">
                    <VscFileCode> </VscFileCode>
                  </i>
                </div>
                <h4 className="text-white fontelection10">
                  {dataTitleServicesCard4}
                </h4>
                <p className="text-white fontelection11">
                  {dataContentServicesCard4}
                </p>
                <a className="btn-circle" href="/service-details">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-blue">
                <h3>{dataTitleServicesCard12}</h3>
                <p className="fontelection12">{dataContentServicesCard12}</p>
                {/* <a href="/service-details" className="theme-btn text-white">
                  {dataReadMore4}
                </a> */}
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-4s">
              <div className="service-normal">
                <div className="icon center2">
                  <i className="marginstops14">
                    <BsCurrencyBitcoin> </BsCurrencyBitcoin>
                  </i>
                </div>
                <h4 className="text-white fontelection10">
                  {dataTitleServicesCard5}
                </h4>
                <p className="text-white fontelection11">
                  {dataContentServicesCard5}
                </p>
                <a className="btn-circle" href="/service-details">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-blue ">
                <h3>{dataTitleServicesCard13}</h3>
                <p className="fontelection12">{dataContentServicesCard13}</p>
                {/* <a href="/service-details" className="theme-btn text-white">
                  {dataReadMore5}
                </a> */}
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-4s">
              <div className="service-normal">
                <div className="icon center2">
                  <i className="marginstops14">
                    <TbCloudComputing> </TbCloudComputing>
                  </i>
                </div>
                <h4 className="text-white fontelection10">
                  {dataTitleServicesCard6}
                </h4>
                <p className="text-white fontelection11">
                  {dataContentServicesCard6}
                </p>
                <a className="btn-circle" href="/service-details">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-blue ">
                <h3>{dataTitleServicesCard14}</h3>
                <p className="fontelection12">{dataContentServicesCard14}</p>
                {/* <a href="/service-details" className="theme-btn text-white">
                  {dataReadMore6}
                </a> */}
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-6s">
              <div className="service-normal">
                <div className="icon center2">
                  <i className="marginstops14">
                    <MdDeveloperMode> </MdDeveloperMode>
                  </i>
                </div>
                <h4 className="text-white fontelection10">
                  {dataTitleServicesCard7}
                </h4>
                <p className="text-white fontelection11">
                  {dataContentServicesCard7}
                </p>
                <a className="btn-circle" href="/service-details">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-blue ">
                <h3>{dataTitleServicesCard15}</h3>
                <p className="fontelection12">{dataContentServicesCard15}</p>
                {/* <a href="/service-details" className="theme-btn text-white">
                  {dataReadMore7}
                </a> */}
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-8s">
              <div className="service-normal">
                <div className="icon ">
                  <i className="marginstops14">
                    <GiProgression> </GiProgression>
                  </i>
                </div>
                <h4 className="text-white fontelection10">
                  {dataTitleServicesCard8}
                </h4>
                <p className="text-white fontelection11">
                  {dataContentServicesCard8}
                </p>
                <a className="btn-circle" href="/service-details">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-blue">
                <h3>{dataTitleServicesCard16}</h3>
                <p className="fontelection12">{dataContentServicesCard16}</p>
                {/* <a href="/service-details" className="theme-btn text-white">
                  {dataReadMore8}
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServiceSection;
