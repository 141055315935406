import React, { useEffect, useState } from "react";
// import background from "../../../assets/images/contact/home-two.jpg";

export default function MainSection() {
  const [dataTitleServicesDetails3, setData115] = useState("");
  const [dataTitleServicesDetails4, setData116] = useState("");
  const [datasubTitleServicesDetails1, setData117] = useState("");
  const [datasubContentServicesDetails1, setData118] = useState("");
  const [datasubTitleServicesDetails2, setData119] = useState("");
  const [datasubContentServicesDetails2, setData120] = useState("");
  const [datasubTitleServicesDetails3, setData121] = useState("");
  const [datasubContentServicesDetails3, setData122] = useState("");
  const [datasubTitleServicesDetails4, setData123] = useState("");
  const [datasubContentServicesDetails4, setData124] = useState("");

  useEffect(() => {
    consultThree();
  }, [localStorage.getItem("id_language")]);

  const consultThree = () => {
    fetch(
      `${
        process.env.REACT_APP_API
      }/ds-pro-services-details/${localStorage.getItem("id_language")}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData115(data.data.attributes.title_servicesdetails_3);
        setData116(data.data.attributes.title_servicesdetails_4);
        setData117(data.data.attributes.subtitle_servicesdetails_1);
        setData118(data.data.attributes.subcontent_servicesdetails_1);
        setData119(data.data.attributes.subtitle_servicesdetails_2);
        setData120(data.data.attributes.subcontent_servicesdetails_2);
        setData121(data.data.attributes.subtitle_servicesdetails_3);
        setData122(data.data.attributes.subcontent_servicesdetails_3);
        setData123(data.data.attributes.subtitle_servicesdetails_4);
        setData124(data.data.attributes.subcontent_servicesdetails_4);
      });
  };

  return (
    <section
      className="about-section-six  bgs-cover py-120 rpb-100 text-white "
      style={{
        backgroundImage:
          // eslint-disable-next-line prefer-template
          `url(${process.env.REACT_APP_API_SERVICES_DETAILS_BACKGROUND1})`,
      }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="about-six-content mr-65 rmr-0 wow fadeInLeft delay-0-2s">
              <div className="section-title mb-35">
                <span className="sub-title">{dataTitleServicesDetails3}</span>
                <h2>{dataTitleServicesDetails4}</h2>
              </div>
              <div className="service-item-six">
                <div className="icon">
                  <i className="flaticon flaticon-3d-printing"></i>
                </div>
                <div className="service-content">
                  <h5>{datasubTitleServicesDetails1}</h5>
                  <p>{datasubContentServicesDetails1}</p>
                </div>
              </div>
              <div className="service-item-six">
                <div className="icon">
                  <i className="flaticon flaticon-bars"></i>
                </div>
                <div className="service-content">
                  <h5>{datasubTitleServicesDetails2}</h5>
                  <p>{datasubContentServicesDetails2}</p>
                </div>
              </div>
              <div className="service-item-six">
                <div className="icon">
                  <i className="flaticon flaticon-bars"></i>
                </div>
                <div className="service-content">
                  <h5>{datasubTitleServicesDetails3}</h5>
                  <p>{datasubContentServicesDetails3}</p>
                </div>
              </div>
              <div className="service-item-six">
                <div className="icon">
                  <i className="flaticon flaticon-bars"></i>
                </div>
                <div className="service-content">
                  <h5>{datasubTitleServicesDetails4}</h5>
                  <p>{datasubContentServicesDetails4}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="about-shape-six ml-50 rml-30 pr-0 wow fadeInRight delay-0-2s">
              <img
                src={process.env.REACT_APP_API_SERVICESDETAILS5}
                alt="About"
                width="400"
                height="400"
              />
              {/* <img src={process.env.REACT_APP_API_AUTHOR2} alt="Graph" /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
