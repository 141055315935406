import React, { useEffect, useState } from "react";
import { AiOutlineFileProtect } from "react-icons/ai";
import { BiSupport } from "react-icons/bi";
import { BsJournalCode } from "react-icons/bs";

export default function WorkProcessSection() {
  const [dataTitleServices3, setData102] = useState("");
  const [dataTitleServicesCard17, setData103] = useState("");
  const [dataTitleServicesCard18, setData104] = useState("");
  const [dataTitleServicesCard19, setData105] = useState("");
  const [dataContentServicesCard17, setData106] = useState("");
  const [dataContentServicesCard18, setData107] = useState("");
  const [dataContentServicesCard19, setData108] = useState("");

  useEffect(() => {
    consultThree();
  }, [localStorage.getItem("id_language")]);

  const consultThree = () => {
    fetch(
      `${process.env.REACT_APP_API}/ds-pro-services/${localStorage.getItem(
        "id_language"
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData102(data.data.attributes.title_services_3);
        setData103(data.data.attributes.title_services_card_17);
        setData104(data.data.attributes.title_services_card_18);
        setData105(data.data.attributes.title_services_card_19);
        setData106(data.data.attributes.content_services_card_17);
        setData107(data.data.attributes.content_services_card_18);
        setData108(data.data.attributes.content_services_card_19);
      });
  };

  return (
    <section
      className="work-progress-three bgs-cover text-white pt-115 rpt-95 pb-85 rpb-65 containertyle5"
      // style={{
      //   backgroundImage:
      //     // eslint-disable-next-line prefer-template
      //     `url(${process.env.REACT_APP_API_SERVICES_BACKGROUND3})`,
      // }}
    >
      <div className="container">
        <div className="section-title text-center mb-37">
          <h2 className="sub-title">{dataTitleServices3}</h2> <br />
          <br />
          <br />
          <br />
        </div>
        <div className="work-progress-inner-three">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-sm-6">
              <div className="progress-item-three style-three wow fadeInUp delay-0-2s">
                {/* <span className="progress-step">01</span> */}
                <div className="icon center">
                  <BsJournalCode> </BsJournalCode>
                </div>
                <h3 className="marginstops13 fontelectiontyle2">
                  {dataTitleServicesCard17}
                </h3>
                <p className="marginstops15">{dataContentServicesCard17}</p>
                <br />
                <br />
                {/* <a href="/service-details" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a> */}
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="progress-item-three style-three wow fadeInUp delay-0-4s">
                {/* <span className="progress-step">02</span> */}
                <div className="icon center">
                  <AiOutlineFileProtect> </AiOutlineFileProtect>
                </div>
                <h3 className="marginstops13 fontelectiontyle2">
                  {dataTitleServicesCard18}
                </h3>
                <p className="marginstops15">{dataContentServicesCard18}</p>
                {/* <a href="/service-details" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a> */}
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="progress-item-three style-three wow fadeInUp delay-0-6s no-border mb-0">
                {/* <span className="progress-step">03</span> */}
                <div className="icon center">
                  <BiSupport> </BiSupport>
                </div>
                <h3 className="marginstops13 fontelectiontyle2">
                  {dataTitleServicesCard19}
                </h3>
                <p className="marginstops15">{dataContentServicesCard19}</p>
                {/* <a href="/service-details" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
