import React from "react";
import InnerPageDefaultLayoutTeam from "../helpers/InnerPageDefaultLayoutTeam";
// import WorkProcessSection from "../HomeThree/WorkProcessSection";

export default function TeamLayout({ children, pageTitle, breadcrumbs }) {
  return (
    <InnerPageDefaultLayoutTeam pageTitle={pageTitle} breadcrumbs={breadcrumbs}>
      {children && children}
      {/* <WorkProcessSection /> */}
    </InnerPageDefaultLayoutTeam>
  );
}
