/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// Todo: for spacific page rtl disabled
// import { useLocation } from "react-router-dom";
import { CgMail } from "react-icons/cg";
// import { IoShareSocialOutline } from "react-icons/io5";
import { RiWhatsappLine } from "react-icons/ri";

import useToggle from "./hooks/useToggle";
import Routers from "./Routers";

/* eslint-disable */

localStorage.setItem("id_language", 2);
function App() {
  useEffect(() => {
    const timer = setTimeout(() => {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const { rtl } = useSelector((state) => state.rtl);
  // const dispatch = useDispatch();
  const dispatch = useDispatch();
  // const html = document.getElementsByTagName("html");
  const [settingToggle, setToggle] = useToggle(false);

  const [dataLinkgmail, setData300] = useState("");
  const [dataLinkWhatsApp, setData301] = useState("");

  useEffect(() => {
    consult();
  }, [localStorage.getItem("id_language")]);

  const consult = () => {
    fetch(
      `${process.env.REACT_APP_API}/ds-pro-homes/${localStorage.getItem(
        "id_language"
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData300(data.data.attributes.links_home_gmail);
        setData301(data.data.attributes.links_home_whatsapp);
      });
  };
  return (
    <div className={rtl ? "" : ""}>
      <Routers />
      {/* <div class="mainopShadow"></div> */}
      {/* <div class="fab">
        <div class="mainop">
          <i id="addIcon" class="material-icons">
            <IoShareSocialOutline className="center7 "> </IoShareSocialOutline>
          </i>
        </div>

        <div id="sheets" class="minifab op2">
          <i id="addIcon" class="material-icons">
            <a href={dataLinkWhatsApp} target="_blank">
              <RiWhatsappLine className="center8 colorelection-two"></RiWhatsappLine>
            </a>
          </i>
        </div>
        <div id="sheets" class="minifab op4 ">
          <i id="addIcon" class="material-icons">
            <a href={dataLinkgmail} target="_blank">
              {" "}
              <CgMail className="center8 colorelection-two"> </CgMail>{" "}
            </a>
          </i>
        </div>
      </div> */}
      <div className="hero-home">
        <div className="btn-whatsapp">
          <a href={dataLinkWhatsApp} target="_blank">
            {/* <img
              className="btn-whatsapp5"
              src="http://s2.accesoperu.com/logos/btn_whatsapp.png"
              alt=""
            /> */}
            <RiWhatsappLine className="center8 colorelection-two"></RiWhatsappLine>
          </a>
        </div>
        <div className="btn-whatsapp2">
          <a href={dataLinkgmail} target="_blank">
            <CgMail className="center8 colorelection-two"> </CgMail>{" "}
          </a>
        </div>
      </div>
      {/* <div
        className={`layout-settings ${settingToggle ? "active" : ""}`}
        // Todo: for spacific page rtl disabled
        // className={`layout-settings ${
        //   location.pathname !== "/home-eight" ? "d-block" : "d-none"
        // } ${settingToggle ? "active" : ""}`}
      >
        <div className="heading">
          <h1>Idioma/languaje</h1>
        </div>
        <div className="layout-content">
          <ul>
            <li className="content-item">
              <div className="item-name">
                <span>SPANISH</span>
              </div>

              <div className="content-item">
                <button
                  type="Spanish button"
                  onClick={() => {
                    dispatch(rtlToggle());
                    localStorage.setItem("id_language", 1);
                  }}
                >
                  <img
                    className="border-flag "
                    src="https://flagcdn.com/es.svg"
                    alt="flag"
                    width="25px"
                    height="25px"
                  />
                </button>
              </div>
            </li>
            <li className="content-item">
              <div className="item-name">
                <span>ENGLISH</span>
              </div>
              <br />
              <br />
              <div className="content-item">
                <button
                  type="English button"
                  onClick={() => {
                    dispatch(rtlToggle());
                    localStorage.setItem("id_language", 2);
                  }}
                >
                  <img
                    className="border-flag "
                    src="https://flagcdn.com/us.svg"
                    alt="flag"
                    width="25px"
                    height="25px"
                  />
                </button>
              </div>
            </li>
          </ul>
        </div>
        <div className="close-btn-wrapper" onClick={() => setToggle.toggle()}>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </div>
      </div>*/}
    </div>
  );
}

export default App;
