import React, { useEffect, useState } from "react";
import CounterUp from "../helpers/CounterUp";
// import SliderCom from "../helpers/SliderCom";

export default function TestimonialSection() {
  const [dataCounter1, setData30] = useState("");
  const [dataCounter2, setData31] = useState("");
  const [dataCounter3, setData32] = useState("");
  // const [dataCounter4, setData33] = useState("");
  const [dataTitleAbout6, setData34] = useState("");
  const [dataTitleAbout7, setData35] = useState("");
  // const [dataTitleAbout8, setData36] = useState("");
  // const [dataTitleAbout9, setData37] = useState("");
  const [dataContentAbout3, setData38] = useState("");
  const [dataContentAbout4, setData39] = useState("");
  const [dataContentAbout5, setData40] = useState("");
  // const [dataContentAbout6, setData41] = useState("");
  // const [dataAuthorAbout1, setData42] = useState("");
  // const [dataAuthorAbout2, setData43] = useState("");
  // const [dataAuthorAbout3, setData44] = useState("");
  // const [dataAuthorAbout4, setData45] = useState("");
  // const [dataRolAbout1, setData46] = useState("");
  // const [dataRolAbout2, setData47] = useState("");
  // const [dataRolAbout3, setData48] = useState("");
  // const [dataRolAbout4, setData49] = useState("");
  // const [dataNewAbout1, setData50] = useState("");
  // const [dataNewAbout2, setData51] = useState("");
  // const [dataNewAbout3, setData52] = useState("");
  // const [dataNewAbout4, setData53] = useState("");
  // const [dataDateAbout2, setData54] = useState("");
  // const [dataDateAbout3, setData55] = useState("");
  // const [dataDateAbout4, setData56] = useState("");
  // const [dataDateAbout1, setData57] = useState("");

  useEffect(() => {
    consultTwo();
  }, [localStorage.getItem("id_language")]);

  const consultTwo = () => {
    fetch(
      `${process.env.REACT_APP_API}/ds-pro-abouts/${localStorage.getItem(
        "id_language"
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData30(data.data.attributes.counter_about_1);
        setData31(data.data.attributes.counter_about_2);
        setData32(data.data.attributes.counter_about_3);
        // setData33(data.data[0].attributes.counter_about_4);
        setData34(data.data.attributes.title_about_6);
        setData35(data.data.attributes.title_about_7);
        // setData36(data.data[0].attributes.title_about_8);
        // setData37(data.data.attributes.title_about_9);
        setData38(data.data.attributes.content_about_3);
        setData39(data.data.attributes.content_about_4);
        setData40(data.data.attributes.content_about_5);
        // setData41(data.data[0].attributes.content_about_6);
        // setData42(data.data.attributes.author_about_1);
        // setData43(data.data.attributes.author_about_2);
        // setData44(data.data.attributes.author_about_3);
        // setData45(data.data.attributes.author_about_4);
        // setData46(data.data.attributes.rol_about_1);
        // setData47(data.data.attributes.rol_about_2);
        // setData48(data.data.attributes.rol_about_3);
        // setData49(data.data.attributes.rol_about_4);
        // setData50(data.data.attributes.new_about_1);
        // setData51(data.data.attributes.new_about_2);
        // setData52(data.data.attributes.new_about_3);
        // setData53(data.data.attributes.new_about_4);
        // setData54(data.data.attributes.date_about_1);
        // setData55(data.data.attributes.date_about_2);
        // setData56(data.data.attributes.date_about_3);
        // setData57(data.data.attributes.date_about_4);
      });
  };

  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   autoplay: true,
  //   autoplaySpeed: 5000,
  //   arrows: false,
  //   speed: 1000,
  //   slidesToShow: 2,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 767,
  //       settings: {
  //         slidesToShow: 1,
  //       },
  //     },
  //   ],
  // };
  return (
    <section
      className="testimonial-section bg-lighter pt-5 rpt-95 pb-5 rpb-0 containertyle16"
      id="testimonial-section"
    >
      <div className="container">
        <div className="section-title text-center mb-65 text-white fontelection3">
          <h2 className="marginstops11">{dataTitleAbout6}</h2> <br />
          <span>{dataTitleAbout7}</span>
        </div>
        <div className="fact-counter-color text-center mb-30 ">
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="success-item wow fadeInUp delay-0-2s">
                <span className="count-text plus">
                  <CounterUp
                    className="text-white "
                    sectionSelect="testimonial-section"
                    endValue={dataCounter1}
                  />
                </span>
                <h4 className="text-white fontelection">{dataContentAbout3}</h4>
              </div>
            </div>
            <div className="line2"></div>
            <div className="col-lg-3 col-md-4 col-sm-6 ">
              <div className="success-item wow fadeInUp delay-0-4s">
                <span className="count-text plus ">
                  <CounterUp
                    sectionSelect="testimonial-section"
                    endValue={dataCounter2}
                  />
                </span>
                <h4 className="text-white fontelection">{dataContentAbout4}</h4>
              </div>
            </div>
            <div className="line2"></div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="success-item wow fadeInUp delay-0-6s">
                <span className="count-text plus">
                  <CounterUp
                    sectionSelect="testimonial-section"
                    endValue={dataCounter3}
                  />
                </span>
                <h4 className="text-white fontelection">{dataContentAbout5}</h4>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="testimonial-wrap ">
          <div className="section-title text-center mb-65">
            <span className="sub-title"></span>
            <h2 className="text-white marginstops8 fontelection5">
              {dataTitleAbout9}
            </h2>
          </div>
          <SliderCom settings={settings}>
            <div className="testimonial-item wow fadeInLeft delay-0-2s containertyle3">
              <div className="author-description">
                <img src={process.env.REACT_APP_API_AUTHOR1} alt="Author" />
                <div className="designation">
                  <h5 className="fontelection7">{dataAuthorAbout1}</h5>
                  <span className="fontelection6">{dataRolAbout1}</span>
                </div>
                <i className="fas fa-quote-right"></i>
              </div>
              <hr className="lineup3" />
              <p className="fontelection8">{dataNewAbout1}</p>
              <h5 className="fontelection9">{dataDateAbout1}</h5>
            </div>
            <div className="testimonial-item wow fadeInRight delay-0-2s containertyle3 marginstops9">
              <div className="author-description">
                <img src={process.env.REACT_APP_API_AUTHOR2} alt="Author" />
                <div className="designation">
                  <h5 className="fontelection7">{dataAuthorAbout2}</h5>
                  <span className="fontelection6">{dataRolAbout2}</span>
                </div>
                <i className="fas fa-quote-right"></i>
              </div>

              <hr className="lineup3" />
              <p className="fontelection8">{dataNewAbout2}</p>
              <h5 className="fontelection9">{dataDateAbout2}</h5>
            </div>
            <div className="testimonial-item wow fadeInRight delay-0-2s containertyle3  marginstops9">
              <div className="author-description">
                <img src={process.env.REACT_APP_API_AUTHOR3} alt="Author" />
                <div className="designation">
                  <h5 className="fontelection7">{dataAuthorAbout3}</h5>
                  <span className="fontelection6">{dataRolAbout3}</span>
                </div>
                <i className="fas fa-quote-right"></i>
              </div>
              <hr className="lineup3" />
              <p className="fontelection8">{dataNewAbout3}</p>
              <h5 className="fontelection9">{dataDateAbout3}</h5>
            </div>
            <div className="testimonial-item wow fadeInRight delay-0-2s containertyle3  marginstops9">
              <div className="author-description">
                <img src={process.env.REACT_APP_API_AUTHOR4} alt="Author" />
                <div className="designation">
                  <h5 className="fontelection7">{dataAuthorAbout4}</h5>
                  <span className="fontelection6">{dataRolAbout4}</span>
                </div>
                <i className="fas fa-quote-right"></i>
              </div>
              <hr className="lineup3" />
              <p className="fontelection8">{dataNewAbout4}</p>
              <h5 className="fontelection9">{dataDateAbout4}</h5>
            </div>
          </SliderCom>
        </div> */}
      </div>
    </section>
  );
}
