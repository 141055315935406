import React from "react";
import InnerPageDefaultLayoutProjectsDetails from "../../helpers/InnerPageDefaultLayoutProjectsDetails";
import MainSection from "./MainSection";

export default function ProtfolioDetails() {
  return (
    <InnerPageDefaultLayoutProjectsDetails
      pageTitle="Portfolio"
      breadcrumbs={[
        { name: "home", path: "/" },
        { name: "portfolio  details", path: "/portfolio-details" },
      ]}
    >
      <MainSection />
    </InnerPageDefaultLayoutProjectsDetails>
  );
}
