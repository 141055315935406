import React, { useEffect, useState } from "react";

// eslint-disable-next-line no-unused-vars
export default function Hero8() {
  const tab = <>&nbsp;&nbsp;</>;
  const [dataTitleContact1, setData250] = useState("");
  const [dataTitleContact2, setData251] = useState("");
  const [dataTitleContact3, setData252] = useState("");

  useEffect(() => {
    consultSix();
  }, [localStorage.getItem("id_language")]);

  const consultSix = () => {
    fetch(
      `${process.env.REACT_APP_API}/ds-pro-contacts/${localStorage.getItem(
        "id_language"
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData250(data.data.attributes.title_contact1);
        setData251(data.data.attributes.title_contact2);
        setData252(data.data.attributes.title_contact3);
      });
  };

  return (
    <section
      className="hero-section-two bgs-cover overlay pt-40 rpt-80 "
      style={{
        backgroundImage:
          // eslint-disable-next-line prefer-template
          `url(${process.env.REACT_APP_API_CONTACT_BACKGROUND})`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-10 col-sm-11">
            <div className=" text-white  py-220 rpy-120">
              <div className="testimonial-two-active">
                <div className="terms-two">
                  <h2 className="fontelectiontyle"> </h2>
                  <h3 className="wow fadeInUp delay-0-4s mt-20">
                    <a className=" fontelection fontsize" href="/">
                      {tab}
                      {dataTitleContact2}
                      {tab}
                    </a>
                  </h3>

                  <h3 className="wow fadeInUp delay-0-4s mt-20 fontelection ">
                    {" "}
                    /{" "}
                  </h3>

                  <h3 className="wow fadeInUp delay-0-4s mt-20">
                    <a className="fontelection fontsize" href="/contact">
                      {tab}
                      {dataTitleContact3}
                    </a>
                  </h3>
                </div>{" "}
                <div className="testimonial-two-active">
                  <br /> <br />
                  <h2 className="wow fadeInUp delay-0-4s rml-1 marginstops fontelection2">
                    {dataTitleContact1}
                  </h2>
                </div>
              </div>
              {/* <div className="hero-btns mt-35 wow fadeInUp delay-0-6s">
                <a href="/contact" className="theme-btn mr-25">
                  {dataContact}
                </a>
                <a
                  onClick={(e) => videoHandler(e)}
                  href="#"
                  className="mfp-iframe video-play"
                >
                  <i className="fas fa-play"></i>
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
