import React, { useEffect, useState } from "react";

// eslint-disable-next-line no-unused-vars
export default function Hero5() {
  const tab = <>&nbsp;&nbsp;</>;
  const [dataTitleprojects3, setData150] = useState("");
  const [dataTitleprojects4, setData151] = useState("");
  const [dataTitleprojects5, setData152] = useState("");

  // const [dataContact, setData3] = useState("");

  // fetch(process.env.REACT_APP_API_NADVAR, {
  //   method: "GET",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // })
  //   .then((response) => response.json())
  //   .then((data) => {
  //     setData3(data.data[0].attributes.contact);
  //   });

  useEffect(() => {
    consultFour();
  }, [localStorage.getItem("id_language")]);

  const consultFour = () => {
    fetch(
      `${process.env.REACT_APP_API}/ds-pro-projects-langs?locale=${
        localStorage.getItem("id_language") === "1" ? "es-CR" : "en"
      }&populate=*`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData150(data.data[0].attributes.title_projects);
        setData151(data.data[0].attributes.title_projects2);
        setData152(data.data[0].attributes.title_projects3);
      });
  };

  return (
    <section
      className="hero-section-two bgs-cover overlay pt-40 rpt-80 containertyle17"
      // eslint-disable-next-line no-useless-concat
      // style={{
      //   backgroundImage:
      //     // eslint-disable-next-line prefer-template
      //     `url(${process.env.REACT_APP_API_PROJECTS_BACKGROUND})`,
      // }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-10 col-sm-11">
            <div className=" text-white py-150 rpy-150">
              <div className="testimonial-two-active">
                <div className="terms">
                  <h2 className="fontelectiontyle"> </h2>
                  <h3 className="wow fadeInUp delay-0-4s mt-20">
                    <a className=" fontelection fontsize" href="/">
                      {tab}
                      {dataTitleprojects4}
                      {tab}
                    </a>
                  </h3>

                  <h3 className="wow fadeInUp delay-0-4s mt-20 fontelection ">
                    {" "}
                    /{" "}
                  </h3>
                  <h3 className="wow fadeInUp delay-0-4s mt-20">
                    <a className="fontelection fontsize" href="/portfolio">
                      {tab}
                      {dataTitleprojects5}
                    </a>
                  </h3>
                </div>

                <div className="testimonial-two-active">
                  <br /> <br />
                  <h2 className="wow fadeInUp delay-0-4s rml-1 marginstops fontelection2">
                    {dataTitleprojects3}
                  </h2>
                </div>
              </div>
              {/* <div className="hero-btns mt-35 wow fadeInUp delay-0-6s">
                <a href="/contact" className="theme-btn mr-25">
                  {dataContact}
                </a>
                <a
                  onClick={(e) => videoHandler(e)}
                  href="#"
                  className="mfp-iframe video-play"
                >
                  <i className="fas fa-play"></i>
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
