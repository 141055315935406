import React, { useEffect, useState } from "react";
import SliderCom from "../helpers/SliderCom";
// eslint-disable-next-line no-unused-vars
export default function Hero() {
  const [dataInf, setData] = useState("");
  const [dataCont, setData2] = useState("");
  const settings = {
    // dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  // const [dataContact, setData3] = useState("");

  // fetch(process.env.REACT_APP_API_NADVAR, {
  //   method: "GET",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // })
  //   .then((response) => response.json())
  //   .then((data) => {
  //     setData3(data.data[0].attributes.contact);
  //   });
  useEffect(() => {
    consult();
  }, [localStorage.getItem("id_language")]);

  const consult = () => {
    fetch(
      `${process.env.REACT_APP_API}/ds-pro-homes/${localStorage.getItem(
        "id_language"
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData(data.data.attributes.title);
        setData2(data.data.attributes.content);
        // setData3(data.data[0].attributes.contact);
      });
  };

  return (
    <section
      className="hero-section-two bgs-cover overlay pt-40 rpt-80"
      // eslint-disable-next-line no-useless-concat
      style={{
        backgroundImage:
          // eslint-disable-next-line prefer-template
          `url(${process.env.REACT_APP_API_IMAGE})`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-10 col-sm-11">
            <div className=" text-white py-220 rpy-120">
              <div className="testimonial-two-active">
                <SliderCom className="hero-content2" settings={settings}>
                  <h1 className="wow fadeInUp delay-0-4s mt-20 fontelection2 ">
                    {dataInf}
                  </h1>

                  <h1 className="wow fadeInUp delay-0-4s mt-20 fontelection2 hero-content2">
                    {dataCont}
                  </h1>

                  {/* <div className="hero-btns mt-35 wow fadeInUp delay-0-6s">
                <a href="/contact" className="theme-btn mr-25">
                  {dataContact}
                </a>
                <a
                  onClick={(e) => videoHandler(e)}
                  href="#"
                  className="mfp-iframe video-play"
                >
                  <i className="fas fa-play"></i>
                </a>
              </div> */}
                </SliderCom>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
