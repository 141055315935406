import React, { useEffect, useState } from "react";

import SliderCom from "../helpers/SliderCom";

/* eslint-disable */
export default function WorkProcessSection() {
  const [dataCarrusel, setDataCarrusel] = useState([]);

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  useEffect(() => {
    consult();
  }, [localStorage.getItem("id_language")]);

  const consult = () => {
    fetch(
      `${process.env.REACT_APP_API}/ds-pro-carrusel-langs?locale=${
        localStorage.getItem("id_language") === "1" ? "es-CR" : "en"
      }&populate=*`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setDataCarrusel(data.data);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <section className="work-progress-three bgs-cover text-white pb-85 rpb-65 containertyle5">
      <div className="container">
        <div className="logo-carousel-wrap  style-two bg-white py-100 px-25 br-5">
          <SliderCom settings={settings}>
            {dataCarrusel.length > 0
              ? dataCarrusel.map((item, index) => (
                  <div key={index} className="logo-item">
                    {/* eslint-disable-next-line  */}

                    <img
                      src={`https://api.preview.dspro.tech${item.attributes.carrusel_link}`}
                      alt="Language Logo"
                      width="127"
                      height="52"
                    />
                  </div>
                ))
              : ""}
            {/* <div className="logo-item">
              <img
                src={process.env.REACT_APP_API_CARRUCEL2}
                alt="java Logo"
                width="127"
                height="52"
              />
            </div>
            <div className="logo-item">
              <img
                src={process.env.REACT_APP_API_CARRUCEL3}
                alt="JS Logo"
                width="127"
                height="52"
              />
            </div>
            <div className="logo-item">
              <img
                src={process.env.REACT_APP_API_CARRUCEL4}
                alt="PHP Logo"
                width="127"
                height="52"
              />
            </div>
            <div className="logo-item">
              <img
                src={process.env.REACT_APP_API_CARRUCEL5}
                alt="MySql Logo"
                width="127"
                height="52"
              />
            </div>
            <div className="logo-item">
              <img
                src={process.env.REACT_APP_API_CARRUCEL6}
                alt="Angular Logo"
                width="127"
                height="52"
              />
            </div>
            <div className="logo-item">
              <img
                src={process.env.REACT_APP_API_CARRUCEL7}
                alt="ASM Logo"
                width="127"
                height="52"
              />
            </div>
            <div className="logo-item">
              <img
                src={process.env.REACT_APP_API_CARRUCEL8}
                alt="C# Logo"
                width="127"
                height="52"
              />
            </div>
            <div className="logo-item">
              <img
                src={process.env.REACT_APP_API_CARRUCEL9}
                alt="C++ Logo"
                width="127"
                height="52"
              />
            </div>
            <div className="logo-item">
              <img
                src={process.env.REACT_APP_API_CARRUCEL10}
                alt="CSS Logo"
                width="127"
                height="52"
              />
            </div>
            <div className="logo-item">
              <img
                src={process.env.REACT_APP_API_CARRUCEL11}
                alt="Go Logo"
                width="127"
                height="52"
              />
            </div>
            <div className="logo-item">
              <img
                src={process.env.REACT_APP_API_CARRUCEL12}
                alt="HTML5 Logo"
                width="127"
                height="52"
              />
            </div>
            <div className="logo-item">
              <img
                src={process.env.REACT_APP_API_CARRUCEL13}
                alt="JQuery Logo"
                width="127"
                height="52"
              />
            </div>
            <div className="logo-item">
              <img
                src={process.env.REACT_APP_API_CARRUCEL14}
                alt="Net Logo"
                width="127"
                height="52"
              />
            </div>
            <div className="logo-item">
              <img
                src={process.env.REACT_APP_API_CARRUCEL15}
                alt="Node Logo"
                width="127"
                height="52"
              />
            </div>
            <div className="logo-item">
              <img
                src={process.env.REACT_APP_API_CARRUCEL16}
                alt="Pascal Logo"
                width="127"
                height="52"
              />
            </div>
            <div className="logo-item">
              <img
                src={process.env.REACT_APP_API_CARRUCEL17}
                alt="React Logo"
                width="127"
                height="52"
              />
            </div>
            <div className="logo-item">
              <img
                src={process.env.REACT_APP_API_CARRUCEL18}
                alt="Ruby Logo"
                width="127"
                height="52"
              />
            </div>
            <div className="logo-item">
              <img
                src={process.env.REACT_APP_API_CARRUCEL19}
                alt="Rust Logo"
                width="127"
                height="52"
              />
            </div>
            <div className="logo-item">
              <img
                src={process.env.REACT_APP_API_CARRUCEL20}
                alt="Scala Logo"
                width="127"
                height="52"
              />
            </div>
            <div className="logo-item">
              <img
                src={process.env.REACT_APP_API_CARRUCEL21}
                alt="Swift Logo"
                width="127"
                height="52"
              />
            </div> */}
          </SliderCom>
        </div>
        <br />
        <br />
        <br />
        <br />
      </div>
    </section>
  );
}
