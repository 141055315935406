import React, { useEffect, useState } from "react";
// import background from "../../../assets/images/contact/home-two.jpg";

export default function MainSection() {
  const [dataTitleProjectsDetails4, setData210] = useState("");
  const [dataContentProjectsDetails5, setData211] = useState("");
  const [dataTitleProjectsDetails5, setData212] = useState("");
  const [dataSubTitleProjectsDetails1, setData213] = useState("");
  const [dataSubContentProjectsDetails1, setData214] = useState("");
  const [dataSubTitleProjectsDetails2, setData215] = useState("");
  const [dataSubContentProjectsDetails2, setData216] = useState("");
  const [dataSubTitleProjectsDetails3, setData217] = useState("");
  const [dataSubContentProjectsDetails3, setData218] = useState("");
  const [dataSubTitleProjectsDetails4, setData219] = useState("");
  const [dataSubContentProjectsDetails4, setData220] = useState("");

  useEffect(() => {
    consultFour();
  }, [localStorage.getItem("id_language")]);

  const consultFour = () => {
    fetch(
      `${
        process.env.REACT_APP_API
      }/ds-pro-projects-details/${localStorage.getItem("id_language")}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData210(data.data.attributes.title_projects_details4);
        setData211(data.data.attributes.content_projects_details1);
        setData212(data.data.attributes.title_projects_details5);
        setData213(data.data.attributes.subtitle_projects_details1);
        setData214(data.data.attributes.subcontent_projects_details1);
        setData215(data.data.attributes.subtitle_projects_details2);
        setData216(data.data.attributes.subcontent_projects_details2);
        setData217(data.data.attributes.subtitle_projects_details3);
        setData218(data.data.attributes.subcontent_projects_details3);
        setData219(data.data.attributes.subtitle_projects_details4);
        setData220(data.data.attributes.subcontent_projects_details4);
      });
  };

  return (
    <>
      <section className="portfolio-details pt-120 rpt-100 pb-90 rpb-100 containertyle10">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="portfolio-details-content rmb-55 wow fadeInUp delay-0-2s">
                <div className="image mb-35">
                  <img
                    src={process.env.REACT_APP_API_PROJECTS_DETAILS1}
                    alt="Portfolio Details"
                  />
                </div>
                <h2 className="text-white">{dataTitleProjectsDetails4}</h2>
                <p className="text-white">{dataContentProjectsDetails5} </p>
                <div className="row mt-45">
                  <div className="col-sm-6">
                    <div className="image mb-30 wow fadeInUp delay-0-2s">
                      <img
                        src={process.env.REACT_APP_API_PROJECTS_DETAILS2}
                        alt="Portfolio"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="image mb-30 wow fadeInUp delay-0-4s">
                      <img
                        src={process.env.REACT_APP_API_PROJECTS_DETAILS3}
                        alt="Portfolio"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="portfolio-sidebar">
                <div className="widget widget-portfolio-info bg-lighter p-45 wow fadeInUp delay-0-3s text-white">
                  <h3>{dataTitleProjectsDetails5}</h3>
                  <ul>
                    <li>
                      <h4>{dataSubTitleProjectsDetails1}</h4>
                      <p className="widget-title">
                        {dataSubContentProjectsDetails1}
                      </p>
                    </li>
                    <li>
                      <h4>{dataSubTitleProjectsDetails2}</h4>
                      <p className="widget-title">
                        {dataSubContentProjectsDetails2}
                      </p>
                    </li>
                    <li>
                      <h4>{dataSubTitleProjectsDetails3}</h4>
                      <p className="widget-title">
                        {dataSubContentProjectsDetails3}
                      </p>
                    </li>
                    <li>
                      <h4>{dataSubTitleProjectsDetails4}</h4>
                      <p className="widget-title">
                        {dataSubContentProjectsDetails4}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
