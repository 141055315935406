import React, { useEffect, useState } from "react";

export default function ServiceSection() {
  const [dataConte2, setData6] = useState("");
  // const [dataConte3, setData7] = useState("");
  const [dataCardTitle1, setData8] = useState("");
  const [dataCardTitle2, setData9] = useState("");
  const [dataCardTitle3, setData10] = useState("");
  const [dataCardConte1, setData11] = useState("");
  const [dataCardConte2, setData12] = useState("");
  const [dataCardConte3, setData13] = useState("");

  useEffect(() => {
    consult();
  }, [localStorage.getItem("id_language")]);

  const consult = () => {
    fetch(
      `${process.env.REACT_APP_API}/ds-pro-homes/${localStorage.getItem(
        "id_language"
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData6(data.data.attributes.content_2);
        // setData7(data.data.attributes.content_3);
        setData8(data.data.attributes.card_title_1);
        setData9(data.data.attributes.card_title_2);
        setData10(data.data.attributes.card_title_3);
        setData11(data.data.attributes.card_content_1);
        setData12(data.data.attributes.card_content_2);
        setData13(data.data.attributes.card_content_3);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <section className="services-four pt-115 rpt-95 pb-210 rpb-150 containertyle4">
      <div className="container ">
        <div className="row justify-content-between text-white align-items-center mb-40">
          <div className="col-lg-6 wow fadeInLeft delay-0-2s">
            <div className="section-title mb-36 ">
              {/* <span className="sub-title">{dataTitle2} </span> */}
              <div className="hero-content2">
                <b className="height2"> {dataConte2}</b>
              </div>

              <br />
              <br />
            </div>
          </div>
          <div className=" col-lg-5 wow fadeInRight delay-0-2s">
            <div className="section-title mb-35">
              {/* <span className="sub-title">{dataConte3} </span> */}
            </div>
          </div>
        </div>
        <div className="row center2">
          <div className="col-lg-4 col-sm-6 center5">
            <div className="service-item-four wow fadeInUp delay-0-2s">
              <div className="image ">
                <img
                  src={process.env.REACT_APP_API_CARD1}
                  alt="Service"
                  width="255"
                  height="164.56"
                />
              </div>
              <div className="service-four-content">
                <div className="service-title-area ">
                  <span className="category fontsize4">{dataCardTitle1}</span>
                  <h4 className="fontelection">{dataCardConte1}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 center5">
            <div className="service-item-four wow fadeInUp delay-0-2s">
              <div className="image ">
                <img
                  src={process.env.REACT_APP_API_CARD2}
                  alt="Service"
                  width="255"
                  height="164.56"
                />
              </div>
              <div className="service-four-content">
                <div className="service-title-area">
                  <span className="category fontsize4">{dataCardTitle2}</span>
                  <h4 className="fontelection">{dataCardConte2}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 center5">
            <div className="service-item-four wow fadeInUp delay-0-2s">
              <div className="image ">
                <img
                  src={process.env.REACT_APP_API_CARD3}
                  alt="Service"
                  width="255"
                  height="164.56"
                />
              </div>
              <div className="service-four-content">
                <div className="service-title-area">
                  <span className="category fontsize4 ">{dataCardTitle3}</span>
                  <h4 className="fontelection">{dataCardConte3}</h4>
                </div>
              </div>
              <div>
                {/* <span className="sub-title">{dataTitle2} </span> */}
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </section>
  );
}
