/* eslint-disable react/button-has-type */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import {
  BsCheck2Circle,
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsReddit,
  BsTwitter,
} from "react-icons/bs";

import { CgLink, CgMail } from "react-icons/cg";
import ReactMarkdown from "react-markdown";
//import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import "../../../assets/css/btn-social.css";
import FooterHomeTwo from "../../partials/Footers/FooterHomeTwo";
import FadeInOut from "./FadeInOut";
// eslint-disable-next-line no-unused-vars

const MainSection = () => {
  function useScroll() {
    const [scrollPosition, setScrollPosition] = useState(0);
    useEffect(() => {
      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset);
    };
    return { scrollPosition };
  }
  const scroll = useScroll();
  function useScreenSize() {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    return { width, height };
  }
  const size = useScreenSize();
  let _setting = {};
  let _settingButton = {};

  const total = (size.width - 933) / 2 + 20;
  size.width < 933
    ? (_setting = { top: "10px", right: "11px" })
    : (_setting = { top: "10px", right: `${total}px` });

  scroll.scrollPosition > 150
    ? (_settingButton = { position: "fixed" })
    : (_settingButton = { position: "relative" });

  let _settingButton2 = {};
  scroll.scrollPosition > 150
    ? (_settingButton2 = {
        position: "fixed",
        background: "rgba(52, 52, 52, 0.5)",
        height: "60px",
        top: "115px",
        paddingTop: "0px",
        marginTop: "30px",
      })
    : (_settingButton2 = {
        position: "fixed",
        background: "rgba(52, 52, 52, 0.5)",
        height: "62px",
        paddingTop: "0px",
        marginTop: "0px",
      });

  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);
  const extraStyles = {
    // position: "fixed",
    // top: "30px",
    // left: 0,
    // right: 0,
    // bottom: 0,
    // background: "rgba(0, 0, 0, 0.4)",
    // color: "#FFF"
  };

  const params = useParams();
  const [viewBlog, setviewBlog] = useState({});
  const [valueLenguage, setValueLenguage] = useState(1);
  useEffect(() => {
    consult(2);
  }, []);
  const consult = (id_language) => {
    fetch(
      `${process.env.REACT_APP_API}/ds-pro-blogs?locale=${
        id_language.toString() === "1" ? "es-CR" : "en"
      }&populate=*`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        for (let i = 0; i < data.data.length; i++) {
          if (params.title === data.data[i].attributes.blog_url) {
            setviewBlog(data.data[i]);
            id_language.toString() === "1"
              ? setValueLenguage(2)
              : setValueLenguage(1);
          }
        }
      });
  };

  const changeShow = () => {
    setTimeout(() => {
      setShow(false);
    }, 1500);
  };
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    toggleShow();
    changeShow();
  };

  //const dispatch = useDispatch();

  return (
    <>
      <article className="contact-page py-120 rpy-101 containertyle12 text-white containertyle23">
        <div className="container">
          {Object.entries(viewBlog).length > 0 ? (
            <>
              <div className="hero-content">
                <h3 className="text-center marginstops21">
                  {viewBlog.attributes.blog_title}
                </h3>
              </div>
              <div className="row">
                <div className="col-lg-2"></div>

                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-12 text-center">
                      <div className="hero-content4">
                        <img
                          className="rounded"
                          src={`https://api.preview.dspro.tech${viewBlog.attributes.blog_img_url}`}
                          alt="Blog"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 text-left">
                      <br />
                      <br />
                      <br />
                      {viewBlog.attributes.ds_pro_blog_categories.data.length >
                      0
                        ? viewBlog.attributes.ds_pro_blog_categories.data[0]
                            .attributes.blog_android
                        : ""}
                    </div>
                    <div
                      className="col-lg-12 marginstops15"
                      // style={{ textAlign: "justify" }}
                    >
                      <br />
                      <br />
                      <br />
                      <ReactMarkdown>
                        {viewBlog.attributes.blog_content}
                      </ReactMarkdown>
                    </div>
                    <div className="col-lg-12 text-left">
                      <br />
                      <br />
                      <br />
                      {viewBlog.attributes.createdAt}
                    </div>
                  </div>
                </div>

                <div className="col-lg-2"></div>
              </div>
            </>
          ) : (
            ""
          )}
          {
            // viewBlog
          }
          {}
        </div>
      </article>

      <div className="container-btn-1" style={_setting}>
        <br />
        <br />

        <div className="row">
          <div className="col-12">
            <div className="btn-social-float" style={_settingButton}>
              <a
                href={`https://twitter.com/intent/tweet?url=${window.location.href}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <BsTwitter className="center8 colorelection-two"> </BsTwitter>{" "}
              </a>
            </div>
          </div>
          <div className="col-12">
            <div className="btn-social-float" style={_settingButton}>
              <a
                href={`https://www.facebook.com/sharer.php?u=${window.location}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <BsFacebook className="center8 colorelection-two"> </BsFacebook>{" "}
              </a>
            </div>
          </div>
          <div className="col-12">
            <div className="btn-social-float" style={_settingButton}>
              <a
                href={`https://www.linkedin.com/shareArticle?mini=true&url=${window.location}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <BsLinkedin className="center8 colorelection-two"> </BsLinkedin>{" "}
              </a>
            </div>
          </div>
          <div className="col-12">
            <div className="btn-social-float" style={_settingButton}>
              <a href="/" rel="noopener noreferrer" target="_blank">
                <BsInstagram className="center8 colorelection-two">
                  {" "}
                </BsInstagram>{" "}
              </a>
            </div>
          </div>
          <div className="col-12">
            <div className="btn-social-float" style={_settingButton}>
              <a
                href={`https://www.reddit.com/submit?url=${window.location}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <BsReddit className="center8 colorelection-two"> </BsReddit>{" "}
              </a>
            </div>
          </div>
          <div className="col-12">
            <div className="btn-social-float" style={_settingButton}>
              <a
                href={`mailto:?subject=${window.location}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <CgMail className="center8 colorelection-two"> </CgMail>{" "}
              </a>
            </div>
          </div>

          <div className="col-12">
            {Object.entries(viewBlog).length > 0 ? (
              <span className="">
                <ul className="">
                  <button
                    className="btn-social-float"
                    onClick={() => {
                      //dispatch(rtlToggle());
                      localStorage.setItem("id_language", valueLenguage);
                      consult(valueLenguage);
                    }}
                  >
                    {/* <CgLink className="center8 colorelection-two "></CgLink>{" "} */}

                    <span className="fontelectiontyle4">
                      {" "}
                      {viewBlog.attributes.blog_languaje}
                    </span>
                  </button>
                </ul>
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="col-12">
            <button
              className="btn-social-float"
              // style={_settingButton}
              onClick={() => handleCopy(window.location.href)}
            >
              <CgLink className="center8 colorelection-two "></CgLink>{" "}
            </button>
          </div>

          <div className="col-12">
            <FadeInOut show={show} duration={100} style={extraStyles}>
              <div className="btn-social-float" style={_settingButton2}>
                <BsCheck2Circle className="center8 colorelection-two"></BsCheck2Circle>
                <span style={{ fontSize: "12px", color: "white" }}>
                  Copied..
                </span>
              </div>
            </FadeInOut>
          </div>
        </div>
      </div>
      <FooterHomeTwo className="footer-home-three pt-150" />
    </>
  );
};

export default MainSection;
