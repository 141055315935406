import React, { useEffect, useState } from "react";

/* eslint-disable */
export default function MainSection() {
  const [dataProjects, setDataProjects] = useState([]);

  useEffect(() => {
    consultFour();
  }, [localStorage.getItem("id_language")]);

  const consultFour = () => {
    fetch(
      `${process.env.REACT_APP_API}/ds-pro-projects-langs?locale=${
        localStorage.getItem("id_language") === "1" ? "es-CR" : "en"
      }&populate=*`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setDataProjects(data.data);
      });
  };

  return (
    <section className="gallery-section-two overlay pt-0 rpt-0 pb-0 rpb-0 px-0 rpx-0  ">
      <div className="container-fluid containertyle19 ">
        <div className="section-title text-center mb-55">
          {/* <h2 className="text-white">{dataTitleProject1}</h2> <br /> */}
          <br />
          {/* <h2 className=" fontelectiontyle3">{dataTitleProject2}</h2> <br /> */}
        </div>
        <div className="row text-white justify-content-center">
          {dataProjects.length > 0
            ? dataProjects.map((item, index) => (
                <div className="col-xl-4 col-md-6">
                  <div className="gallery-item style-two wow fadeInUp delay-0-4s">
                    <a href={item.attributes.project_link} target="_blank">
                      <img
                        src={`https://api.preview.dspro.tech${item.attributes.project_img_link}`}
                        alt="Gallery"
                      />
                    </a>
                    <div className="gallery-content">
                      <div className="gallery-content-inner">
                        <span>{item.attributes.project_name}</span>
                        <br />
                        <hr className="lineup4" />

                        <span className="line-height3">
                          {item.attributes.project_description}
                        </span>

                        {/* <a href="/portfolio-details" className="learn-more">
                    {dataReadMore2} <i className="fas fa-arrow-right"></i>
                  </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : ""}
        </div>
      </div>
    </section>
  );
}
