import React, { useEffect, useState } from "react";
import GetInTouchSection from "../../AboutPages/AboutOne/GetInTouchSection";
import InnerPageDefaultLayoutServicesWL from "../../helpers/InnerPageDefaultLayoutServicesWL";
// import PricingSection from "../../HomeOne/PricingSection";
import ServiceSection from "../../HomeOne/ServiceSection";
import WorkProcessSection from "../ServiceOne/WorkProcessSection";

export default function ServiceTwo() {
  const [dataTitle5, setData105] = useState("");

  useEffect(() => {
    consultThree();
  }, [localStorage.getItem("id_language")]);

  const consultThree = () => {
    fetch(
      `${process.env.REACT_APP_API}/ds-pro-services/${localStorage.getItem(
        "id_language"
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData105(data.data.attributes.title_services_1);
        // setData3(data.data[0].attributes.contact);
      });
  };

  return (
    <InnerPageDefaultLayoutServicesWL
      pageTitle={dataTitle5}
      breadcrumbs={[
        { name: "Home", path: "/home" },
        { name: "service", path: "/service" },
      ]}
    >
      <ServiceSection />
      <WorkProcessSection />
      {/* <PricingSection /> */}
      <GetInTouchSection />
    </InnerPageDefaultLayoutServicesWL>
  );
}
