import React from "react";
import FooterHomeTwoWL from "../../partials/FootersWhiteLabel/FooterHomeTwoWL/index";
import HeaderHomeThreeWL from "../../partials/HeadersWhitelabel/HeaderHomeThreeWL";
// import Hero from "./Hero";

export default function Layouts({ children, pageTitle, breadcrumbs = [] }) {
  return (
    <>
      <HeaderHomeThreeWL pageTitle={pageTitle} breadcrumbs={breadcrumbs} />
      {/* <Hero pageTitle={pageTitle} breadcrumbs={breadcrumbs} /> */}
      {children && children}
      <FooterHomeTwoWL />
    </>
  );
}
