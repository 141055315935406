import React from "react";
import { Route, Routes } from "react-router-dom";
import AboutSeven from "./components/AboutPages/AboutSeven";
import Blog from "./components/Blog/BlogPages/Blog";
// import Blog from "./components/BlogPages/Blog";
// import BlogDetails from "./components/BlogPages/BlogDetails";
import Contact from "./components/Contact/index";
// import CounterPage from "./components/CounterPage";
import FourZeroFour from "./components/FourZeroFour/index";
// import HomeEight from "./components/HomeEight/index";
// import HomeFive from "./components/HomeFive";
// import HomeFour from "./components/HomeFour";
// import HomeNine from "./components/HomeNine";
// import HomeOne from "./components/HomeOne";
// import HomeSeven from "./components/HomeSeven";
// import HomeSix from "./components/HomeSix";
import HomeThree from "./components/HomeThree";
import HomeThreeWhiteLabel from "./components/HomeThreeWhiteLabel";
// import HomeTwo from "./components/HomeTwo";
import ProtfolioDetails from "./components/PortfolioPages/PortfolioDetails";
import PortfolioThree from "./components/PortfolioPages/PortfolioThree";
import PortfolioThreeWhiteLabel from "./components/PortfolioPages/PortfolioThreeWhiteLabel";
// import PricingFive from "./components/PricingPages/PricingFive/index";
// import PricingFour from "./components/PricingPages/PricingFour/index";
// import PricingOne from "./components/PricingPages/PricingOne";
// import PricingSix from "./components/PricingPages/PricingSix";
// import PricingThree from "./components/PricingPages/PricingThree/index";
// import PricingTwo from "./components/PricingPages/PricingTwo";
import ServiceDetails from "./components/ServicePages/ServiceDetails/index";
import ServiceTwo from "./components/ServicePages/ServiceTwo/index";
import ServiceTwoWhiteLabel from "./components/ServicePages/ServiceTwoWhiteLabel/index";
// // import Shop from "./components/ShopPages/Shop";
// import ShopDetails from "./components/ShopPages/ShopDetails";
// import TeamFour from "./components/TeamPages/TeamFour";
import TeamSingle from "./components/TeamPages/TeamSingle";
import TeamThree from "./components/TeamPages/TeamThree";
// import TestimonialFour from "./components/TestimonialPages/TestimonialFour";

export default function Routers() {
  return (
    <Routes>
      {/* <Route exact path="/" element={<HomeOne />} /> */}
      {/* <Route exact path="/home-two" element={<HomeTwo />} /> */}
      <Route exact path="/" element={<HomeThree />} />
      <Route exact path="/whitelabel" element={<HomeThreeWhiteLabel />} />
      {/* <Route exact path="/home-four" element={<HomeFour />} /> */}
      {/* <Route exact path="/home-five" element={<HomeFive />} /> */}
      {/* <Route exact path="/home-six" element={<HomeSix />} /> */}
      {/* <Route exact path="/home-seven" element={<HomeSeven />} /> */}
      {/* <Route exact path="/home-eight" element={<HomeEight />} /> */}
      {/* <Route exact path="/home-nine" element={<HomeNine />} /> */}
      {/* <Route exact path="/about-one" element={<AboutOne />} />
      <Route exact path="/about-two" element={<AboutTwo />} />
      <Route exact path="/about-Three" element={<AboutThree />} />
      <Route exact path="/about-four" element={<AboutFour />} />
      <Route exact path="/about-five" element={<AboutFive />} />
      <Route exact path="/about-six" element={<AboutSix />} /> */}
      <Route exact path="/about" element={<AboutSeven />} />
      {/* <Route exact path="/service-one" element={<ServiceOne />} /> */}
      <Route exact path="/service" element={<ServiceTwo />} />
      <Route
        exact
        path="/whitelabel/service"
        element={<ServiceTwoWhiteLabel />}
      />
      {/* <Route exact path="/service-three" element={<ServiceThree />} />
      <Route exact path="/service-four" element={<ServiceFour />} />
      <Route exact path="/service-five" element={<ServiceFive />} />
      <Route exact path="/service-six" element={<ServiceSix />} />
      <Route exact path="/service-seven" element={<ServiceSeven />} /> */}
      <Route exact path="/service-details" element={<ServiceDetails />} />
      {/* <Route exact path="/portfolio-one" element={<PortfolioOne />} />
      <Route exact path="/portfolio-two" element={<PortfolioTwo />} /> */}
      <Route exact path="/portfolio" element={<PortfolioThree />} />
      <Route
        exact
        path="/whitelabel/portfolio"
        element={<PortfolioThreeWhiteLabel />}
      />
      {/* <Route exact path="/portfolio-four" element={<PortfolioFour />} /> */}
      <Route exact path="/portfolio-details" element={<ProtfolioDetails />} />
      {/* <Route exact path="/team-one" element={<TeamOne />} />
      <Route exact path="/team-two" element={<TeamTwo />} /> */}
      <Route exact path="/team" element={<TeamThree />} />
      {/* <Route exact path="/team" element={<TeamFour />} /> */}
      <Route exact path="/team-single" element={<TeamSingle />} />
      {/* <Route exact path="/testimonial-one" element={<TestimonialOne />} />
      <Route exact path="/testimonial-two" element={<TestimonialTwo />} />
      <Route exact path="/testimonial-three" element={<TestimonialThree />} /> */}
      {/* <Route exact path="/testimonial" element={<TestimonialFour />} /> */}
      {/* <Route exact path="/pricing" element={<PricingOne />} /> */}
      {/* <Route exact path="/pricing-two" element={<PricingTwo />} /> */}
      {/* <Route exact path="/pricing-three" element={<PricingThree />} /> */}
      {/* <Route exact path="/pricing-four" element={<PricingFour />} /> */}
      {/* <Route exact path="/pricing-five" element={<PricingFive />} /> */}
      {/* <Route exact path="/pricing-six" element={<PricingSix />} /> */}
      {/* <Route exact path="/shop" element={<Shop />} /> */}
      {/* <Route exact path="/shop-details" element={<ShopDetails />} /> */}
      {/* <Route exact path="/blog" element={<Blog />} />
      <Route exact path="/blog/blog-details" element={<BlogDetails />} /> */}
      <Route exact path="/404" element={<FourZeroFour />} />
      <Route exact path="/contact" element={<Contact />} />
      {/* <Route exact path="/counters" element={<CounterPage />} /> */}
      <Route path="/blog/:item/:title" element={<Blog />} />
      <Route path="/blog" element={<Blog />} />
    </Routes>
  );
}
