// import background from "../../assets/images/contact/contact-page.jpg";
import emailjs from "@emailjs/browser";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { IoIosPhonePortrait } from "react-icons/io";
import { SlLocationPin } from "react-icons/sl";
import swal from "sweetalert";
// import InnerPageDefaultLayoutContact from "../helpers/InnerPageDefaultLayoutContact";

/* eslint-disable */

export default function Contact() {
  const form = useRef();
  const [dataLocationContact, setData253] = useState("");
  const [dataEmailContact, setData254] = useState("");
  const [dataWebContact, setData255] = useState("");
  const [dataPhoneContact, setData256] = useState("");
  const [dataTitleContact4, setData257] = useState("");
  const [dataContentContact1, setData258] = useState("");
  const [dataTitleContact5, setData259] = useState("");
  const [dataEmailContactName, setData260] = useState("");
  const [dataEmailContactEmail, setData261] = useState("");
  const [dataEmailContactMessage, setData262] = useState("");
  const [dataContactSend, setData263] = useState("");
  const [dataContactPhone, setData264] = useState("");
  const [dataContactMailto, setData265] = useState("");
  const [dataContactCallto, setData266] = useState("");
  const [dataContactLinkWeb, setData267] = useState("");
  const [dataTitleDropdown, setData268] = useState("");
  const [dataOption1Dropdown, setData269] = useState("");
  const [dataOption2Dropdown, setData270] = useState("");
  const [dataOption3Dropdown, setData271] = useState("");
  const [dataOption4Dropdown, setData272] = useState("");
  const [dataBannerContact1, setData273] = useState("");
  const [dataBannerContact2, setData274] = useState("");
  const [dataBannerContact3, setData275] = useState("");
  const [dataBannerContact4, setData276] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_EMAILJS,
        process.env.REACT_APP_TEMPLATE_EMAILJS,
        form.current,
        process.env.REACT_APP_APIKEY_EMAILJS
      )
      .then(
        (result) => {
          gtag("event", "conversion", {
            send_to: "AW-337623511/rblfCKefhIIYENfz_qAB",
            transaction_id: new Date().getTime(),
          });
          swal({
            title: dataBannerContact1,
            text: dataBannerContact2,
            icon: "success",
            button: "OK",
          });
        },
        (error) => {
          swal({
            title: dataBannerContact3,
            text: dataBannerContact4,
            icon: "warning",
            button: "OK",
          });
        }
      );
  };

  // const voidAlert = ()=>{
  //   swal({
  //   title: "Correo enviado",
  //   text: "Su correo se envio correctamente"
  //   })
  // }

  useEffect(() => {
    consultSix();
  }, [localStorage.getItem("id_language")]);

  const consultSix = () => {
    fetch(
      `${process.env.REACT_APP_API}/ds-pro-contacts/${localStorage.getItem(
        "id_language"
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData253(data.data.attributes.location_contact);
        setData254(data.data.attributes.email_contact);
        setData255(data.data.attributes.web_contact);
        setData256(data.data.attributes.phone_contact);
        setData257(data.data.attributes.title_contact4);
        setData258(data.data.attributes.content_contact1);
        setData259(data.data.attributes.title_contact5);
        setData260(data.data.attributes.email_contact_name);
        setData261(data.data.attributes.email_contact_email);
        setData262(data.data.attributes.email_contact_message);
        setData263(data.data.attributes.email_contact_send);
        setData264(data.data.attributes.phone_contact2);
        setData265(data.data.attributes.mailto_contact);
        setData266(data.data.attributes.callto_contact);
        setData267(data.data.attributes.link_contact);
        setData268(data.data.attributes.dropdown_title);
        setData269(data.data.attributes.dropdown_option_1);
        setData270(data.data.attributes.dropdown_option_2);
        setData271(data.data.attributes.dropdown_option_3);
        setData272(data.data.attributes.dropdown_option_4);
        setData273(data.data.attributes.banner_contact_1);
        setData274(data.data.attributes.banner_contact_2);
        setData275(data.data.attributes.banner_contact_3);
        setData276(data.data.attributes.banner_contact_4);
      });
  };

  return (
    <>
      {/* <InnerPageDefaultLayoutContact> */}
      <section className="contact-page py-120 rpy-101 containertyle12 text-white containertyle23">
        <div className="container">
          <div className="contact-info-area mb-80 rmt-56 text-white">
            <div className="contact-info-item wow fadeInUp rmt-56 delay-0-2s">
              <i>
                <SlLocationPin className="marginstops18"> </SlLocationPin>
              </i>
              <p className="text-white ">{dataLocationContact}, </p>
            </div>
            <div className="contact-info-item wow fadeInUp delay-0-4s">
              <i>
                <AiOutlineMail className="marginstops18"> </AiOutlineMail>
              </i>
              <p>
                <a className="text-white " href={dataContactMailto}>
                  {dataEmailContact}
                </a>

                {/* <a href="#">{dataEmailContact}</a> */}
                <br />
                <br />
                <a className="text-white " href={dataContactLinkWeb}>
                  {dataWebContact}
                </a>
              </p>
            </div>
            <div className="contact-info-item wow fadeInUp delay-0-2s">
              <i>
                <IoIosPhonePortrait className="marginstops18">
                  {" "}
                </IoIosPhonePortrait>
              </i>
              <p>
                <a className="text-white" href={dataContactCallto}>
                  {dataContactPhone}
                </a>{" "}
                <br />
                {/* <a href="callto:+0234(456)9864">+0234 (456) 9864</a> */}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              {/* <div
                  className="contact-form-left bgs-cover h-100 wow fadeInLeft delay-0-2s text-white"
                  // style={{ backgroundImage: `url(${background})` }}
                  style={{
                    backgroundImage:
                      // eslint-disable-next-line prefer-template
                      `url(${process.env.REACT_APP_API_CONTACT})`,
                  }}
                >
                  <h2>{dataTitleContact5}</h2>
                </div> */}
            </div>
            <div className="col-lg-8">
              <div className="contact-form ml-41 rml-0 rmt-55 wow fadeInRight delay-0-2s">
                <h3 className=" text-white">{dataTitleContact4}</h3>

                <p className="">{dataContentContact1}</p>
                <form
                  className="comment-form mt-35"
                  ref={form}
                  onSubmit={sendEmail}
                >
                  <label for="dropdown">{dataTitleDropdown}</label>
                  <select name="dropdown" id="dropdown">
                    <option value="Web page creation" required>
                      {dataOption1Dropdown}
                    </option>
                    <option value="Maintenance" required>
                      {dataOption2Dropdown}
                    </option>
                    <option value="Cloud and server maintenance" required>
                      {dataOption3Dropdown}
                    </option>
                    <option value="Other developments" required>
                      {dataOption4Dropdown}
                    </option>
                  </select>
                  <br />
                  <br />
                  <div className="row clearfix justify-content-center">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="name">
                          <i className="far fa-user"></i>
                        </label>
                        <input
                          type="text"
                          name="name"
                          placeholder={dataEmailContactName}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="email">
                          <i className="far fa-envelope"></i>
                        </label>
                        <input
                          type="email"
                          name="email"
                          placeholder={dataEmailContactEmail}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label htmlFor="message">
                          <i className="fas fa-pencil-alt"></i>
                        </label>
                        <textarea
                          name="message"
                          className="form-control"
                          placeholder={dataEmailContactMessage}
                          required
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group mb-0">
                        <button
                          type="submit"
                          value="Send"
                          className="theme-btn2 fontelection14"
                        >
                          {dataContactSend}
                          <i className="fa fa-rocket"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="hero-content">
            {" "}
            <h1 className="fontelection15 center5">{dataTitleContact5}</h1>
          </div>
        </div>
      </section>

      <div className="contact-page-map">
        <div className="our-location">
          <iframe
            title="map"
            src="https://maps.google.com/maps?width=100%25&amp;height=300&amp;hl=en&amp;q=San%20Jose+(DSPro%20Locati%C3%B3n)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            height="650"
            style={{ border: "0", width: "100%" }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
      {/* </InnerPageDefaultLayoutContact> */}
    </>
  );
}
