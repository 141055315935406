import React from "react";
import InnerPageDefaultLayoutProjectsWL from "../../helpers/InnerPageDefaultLayoutProjectsWL";
import MainSection from "./MainSection";

export default function PortfolioThree() {
  return (
    <InnerPageDefaultLayoutProjectsWL
      pageTitle="Portfolio Three"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Portfolio Three", path: "/portfolio-three" },
      ]}
    >
      <MainSection />
    </InnerPageDefaultLayoutProjectsWL>
  );
}
