import React, { useEffect, useState } from "react";
import { TfiAngleDown } from "react-icons/tfi";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { rtlToggle } from "../../store/rtlSetting";

/* eslint-disable */
function Navigation({ className }) {
  const tab = <>&nbsp;&nbsp;</>;
  const [dataHome, setData] = useState("");
  const [dataAbout, setData2] = useState("");
  const [dataServices, setData3] = useState("");
  const [dataServicesG, setData4] = useState("");
  // const [dataServicesD, setData5] = useState("");
  const [dataPortfolio, setData6] = useState("");
  const [dataPortfolioG, setData7] = useState("");
  // const [dataPorfolioD, setData8] = useState("");
  // const [dataPages, setData9] = useState("");
  // const [dataCounter, setData10] = useState("");
  const [dataTeam, setData11] = useState("");
  const [dataSpanish, setData12] = useState("");
  const [dataEnglish, setData13] = useState("");
  const [dataSpa, setData14] = useState("");
  // const [datalinkSpa, setData16] = useState("");
  // const [datalinkSpanish, setData17] = useState("");
  // const [datalinkEnglish, setData18] = useState("");
  // const [dataTestimonials, setData12] = useState("");
  // const [data404, setData13] = useState("");

  useEffect(() => {
    consultEight();
  }, [localStorage.getItem("id_language")]);

  const consultEight = () => {
    fetch(
      `${process.env.REACT_APP_API}/ds-pro-navbars/${localStorage.getItem(
        "id_language"
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData(data.data.attributes.home);
        setData2(data.data.attributes.about);
        setData3(data.data.attributes.services);
        setData4(data.data.attributes.ourServices);
        // setData5(data.data.attributes.servicesDetails);
        setData6(data.data.attributes.portfolio);
        setData7(data.data.attributes.portfolioGeneral);
        // setData8(data.data.attributes.portfolioDetails);
        // setData9(data.data[0].attributes.pages);
        // setData10(data.data[0].attributes.pagesCounters);
        setData11(data.data.attributes.pagesTeam);
        setData12(data.data.attributes.spanish);
        setData13(data.data.attributes.english);
        setData14(data.data.attributes.spa);
        // setData16(data.data.attributes.link_spa);
        // setData17(data.data.attributes.link_spanish);
        // setData18(data.data.attributes.link_english);
        // setData12(data.data[0].attributes.pagesTestimonials);
        // setData13(data.data[0].attributes.pagesError);
      });
  };

  const dispatch = useDispatch();
  // const [count, setCount] = useState(0);
  // const [isDisabled, setDisabled] = useState(false);

  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }

  return (
    <>
      <div
        className={`restly-header-main-menu fontelection ${className || ""}`}
      >
        <ul>
          <li>
            <Link to="#">
              <li>
                <Link to="/" className="theme-btndrawer ">
                  {" "}
                  {dataHome} <i aria-hidden="true"></i>{" "}
                </Link>
              </li>
            </Link>
          </li>
          <li>
            <Link to="#">
              <li>
                <Link to="/about" className="theme-btndrawer">
                  {" "}
                  {dataAbout} <i aria-hidden="true"></i>{" "}
                </Link>
              </li>
            </Link>
          </li>

          <li>
            <Link to="#">
              <li>
                <Link to="/service" className="theme-btndrawer">
                  {" "}
                  {dataServicesG} <i aria-hidden="true"></i>{" "}
                </Link>
              </li>
            </Link>
          </li>

          <li>
            <Link to="#">
              <li>
                <Link to="/portfolio" className="theme-btndrawer">
                  {" "}
                  {dataPortfolioG} <i aria-hidden="true"></i>{" "}
                </Link>
              </li>
            </Link>
          </li>

          <li>
            <Link to="#">
              <li>
                <Link to="/team" className="theme-btndrawer">
                  {" "}
                  {dataTeam} <i aria-hidden="true"></i>{" "}
                </Link>
              </li>
            </Link>
          </li>

          <li>
            {/* <img
              className="border-flag border-flag-two"
              src={datalinkSpa}
              alt="flag"
              width="25px"
              height="25px"
            /> */}
            <Link to="#">{dataSpa}</Link>
            <TfiAngleDown className="fontsize6"> </TfiAngleDown>
            <ul className="sub-menu">
              {/* <li>
                <button>
                  {dataSpanish}
                  <img
                    align="right"
                    className="border-flag-three  "
                    src={datalinkSpanish}
                    alt="flag"
                    width="25px"
                    height="25px"
                  />
                </button>
              </li> */}
              {/* <li>
                <Link to="/blog/blog-details">
                  {dataEnglish}
                  <img
                    align="right"
                    className="border-flag-three  "
                    src={datalinkEnglish}
                    alt="flag"
                    width="25px"
                    height="25px"
                  />
                </Link>
              </li> */}
              <li>
                <div>
                  <span className="space-two">
                    {/* {dataSpanish}
                    {tab}
                    {tab}
                    {tab}
                    {tab}
                    {tab} */}
                    <button
                      className="size-style boton3"
                      onClick={() => {
                        // setCount(count + 1);

                        if (localStorage.getItem("id_language") != 1) {
                          dispatch(rtlToggle());
                          localStorage.setItem("id_language", 1);
                        }
                      }}
                      //timeout(1000); //for 1 sec delay
                    >
                      <span> {dataSpanish}</span>
                      {/* <img
                        className="space-six"
                        src={datalinkSpanish}
                        align="right"
                        alt="flag"
                        width="25px"
                      /> */}
                    </button>
                    {/* <p>You clicked {count} times</p> */}
                  </span>
                  <div></div>
                </div>
              </li>
              <br />

              <li>
                <div>
                  <span className="space-two">
                    {/* {dataEnglish}
                    {tab}
                    {tab}
                    {tab}
                    {tab}
                    {tab}
                    {tab} */}
                    <button
                      className="size-style boton3"
                      onClick={() => {
                        // setCount(count + 1);

                        if (localStorage.getItem("id_language") != 2) {
                          dispatch(rtlToggle());
                          localStorage.setItem("id_language", 2);
                        }
                      }}
                      //timeout(1000); //for 1 sec delay
                    >
                      <span className="">{dataEnglish}</span>
                      {/* <img
                        className="space-five"
                        align="right"
                        src={datalinkEnglish}
                        alt="flag"
                        width="25px"
                      /> */}
                    </button>
                  </span>
                  <div></div>
                </div>
              </li>
            </ul>
          </li>

          {/* <li>
            <Link to="#">
              {dataPages} <i className="fa fa-angle-down" />
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/counters">{dataCounter}</Link>
              </li>
              <li>
                <Link to="/team">
                  {dataTeam}{" "}
                  <i className="fa fa-info-circle" aria-hidden="true"></i>{" "}
                </Link>
              </li>
              <li>
                <Link to="/testimonial">
                  {dataTestimonials}
                  <i className="fa fa-info-circle" aria-hidden="true"></i>{" "}
                </Link>
              </li>
              <li>
                <Link to="/404">{data404}</Link>
              </li>
            </ul>
          </li> */}

          {/* <li>
            <Link to="/contact">
              Contact <i className="fa fa-rocket" aria-hidden="true"></i>{" "}
            </Link>
          </li> */}
        </ul>
      </div>
    </>
  );
}

export default Navigation;
