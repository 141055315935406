import React, { useEffect, useState } from "react";
import { BiMessageRoundedDetail } from "react-icons/bi";
import { BsHandThumbsUp, BsShieldCheck } from "react-icons/bs";

/* eslint-disable */
export default function WorkProcessSection() {
  const [dataTitle3, setData14] = useState("");
  const [dataTitle4, setData15] = useState("");
  const [dataTitle5, setData16] = useState("");
  const [dataConte4, setData17] = useState("");
  const [dataConte5, setData18] = useState("");
  const [dataConte6, setData19] = useState("");
  const [dataReadmore1, setData20] = useState("");
  const [dataReadmore2, setData21] = useState("");
  const [dataReadmore3, setData22] = useState("");
  const [dataTitle6, setData23] = useState("");
  const [dataTitle7, setData24] = useState("");

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  useEffect(() => {
    consult();
  }, [localStorage.getItem("id_language")]);

  const consult = () => {
    fetch(
      `${process.env.REACT_APP_API}/ds-pro-homes/${localStorage.getItem(
        "id_language"
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData14(data.data.attributes.title_3);
        setData15(data.data.attributes.title_4);
        setData16(data.data.attributes.title_5);
        setData17(data.data.attributes.content_4);
        setData18(data.data.attributes.content_5);
        setData19(data.data.attributes.content_6);
        setData20(data.data.attributes.read_more_1);
        setData21(data.data.attributes.read_more_2);
        setData22(data.data.attributes.read_more_3);
        setData23(data.data.attributes.title_6);
        setData24(data.data.attributes.title_7);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <section className="work-progress-three bgs-cover text-white pb-85 rpb-65 containertyle5">
      <div className="container">
        <br />
        <br />
        <br />
        <br />
        <div className="hero-content2">
          <b className="center2">{dataTitle7}</b>
          <br />
        </div>
        <div className="hero-content2">
          {" "}
          <b className="center2">{dataTitle6}</b>
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="work-progress-inner-three">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-sm-6">
              <div className="progress-item-two style-two wow fadeInUp delay-0-2s">
                {/* <span className="progress-step">01</span> */}
                <div className="icon center5">
                  <BiMessageRoundedDetail> </BiMessageRoundedDetail>
                </div>
                <h3 className="marginstops11">{dataTitle3}</h3>
                <h4 className="fontelection">{dataConte4}</h4>
                <br />
                <br />

                {/* <a href="/service-details" className="learn-more">
                  {dataReadmore1}
                  <i className="fas fa-arrow-right"></i>
                </a> */}
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 ">
              <div className="progress-item-two style-two wow fadeInUp delay-0-4s">
                {/* <span className="progress-step">02</span> */}
                <div className="icon center5">
                  {/* <i className="far fa-comments"></i> */}
                  <BsShieldCheck> </BsShieldCheck>
                </div>
                <h3 className="marginstops11">{dataTitle4}</h3>
                <h4 className="fontelection">{dataConte5}</h4>
                {/* <a href="/service-details" className="learn-more">
                  {dataReadmore2} <i className="fas fa-arrow-right"></i>
                </a> */}
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 ">
              <div className="progress-item-two style-two wow fadeInUp delay-0-6s no-border mb-0">
                {/* <span className="progress-step">03</span> */}
                <div className="icon center5">
                  {/* <i className="far fa-comments"></i> */}
                  <BsHandThumbsUp> </BsHandThumbsUp>
                </div>
                <h3 className="marginstops11">{dataTitle5}</h3>
                <h4 className="fontelection">{dataConte6}</h4>
                <br />
                <br />
                {/* <a href="/service-details" className="learn-more">
                  {dataReadmore3} <i className="fas fa-arrow-right"></i>
                </a> */}
              </div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
