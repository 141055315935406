import React from "react";
import useToggle from "../../../../hooks/useToggle";
import DrawerWhiteLabel from "../../../helpers/DrawerWhiteLabel";

export default function MobileHeaderCom({ className }) {
  const [drawer, setValue] = useToggle(false);

  // const [dataContact, setData3] = useState("");

  // fetch(
  //   `${process.env.REACT_APP_API}/ds-pro-navbars/${localStorage.getItem(
  //     "id_language"
  //   )}`,
  //   {
  //     method: "GET",
  //     headers: {
  // Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
  //       "Content-Type": "application/json",
  //     },
  //   }
  // )
  //   .then((response) => response.json())
  //   .then((data) => {
  //     setData3(data.data.attributes.contact);
  //   });

  return (
    <>
      <DrawerWhiteLabel drawer={drawer} action={setValue.toggle} />
      <div className={`mobile-header d-block d-lg-none ${className || ""}`}>
        <div
          className="d-flex justify-content-between"
          style={{ alignItems: "center", height: "100%" }}
        >
          <a href="/whitelabel" className="logo-area" alt="home">
            <img
              src={process.env.REACT_APP_API_LAUNCH}
              alt="Launch Logo"
              width="130"
              height="73"
            />
          </a>
          {/* <div className="button-area">
            <a href="/contact" className="theme-btn3 fontelection16">
              {dataContact} <i aria-hidden="true"></i>
            </a>
          </div> */}
          <div className="button-area">
            <span onClick={setValue.toggle}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ width: "30px", height: "30px", color: "white" }}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h7"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
