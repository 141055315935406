import React, { useEffect, useState } from "react";
// import thumbnail from "../../assets/images/testimonials/testimonial-three.jpg";
import SliderCom from "../helpers/SliderCom";

export default function TestimonialSection({ className }) {
  const [dataTitleServicesDetails2, setData108] = useState("");
  const [dataContentServicesDetails1, setData109] = useState("");
  const [dataTitleServicesDetails8, setData110] = useState("");
  const [dataTitleServicesDetails9, setData111] = useState("");
  const [dataTitleServicesDetails10, setData112] = useState("");
  const [dataContentServicesDetails2, setData113] = useState("");
  const [dataContentServicesDetails3, setData114] = useState("");
  const [dataContentServicesDetails4, setData115] = useState("");

  useEffect(() => {
    consultThree();
  }, [localStorage.getItem("id_language")]);

  const consultThree = () => {
    fetch(
      `${
        process.env.REACT_APP_API
      }/ds-pro-services-details/${localStorage.getItem("id_language")}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData108(data.data.attributes.title_servicesdetails_2);
        setData109(data.data.attributes.content_servicesdetails_1);
        setData110(data.data.attributes.title_servicesdetails_8);
        setData111(data.data.attributes.title_servicesdetails_9);
        setData112(data.data.attributes.title_servicesdetails_10);
        setData113(data.data.attributes.content_servicesdetails_2);
        setData114(data.data.attributes.content_servicesdetails_3);
        setData115(data.data.attributes.content_servicesdetails_4);

        // setData3(data.data[0].attributes.contact);
      });
  };

  const settings = {
    dots: false,
    infinite: false,
    autoplay: false,
    fade: true,
    autoplaySpeed: 5000,
    arrows: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const settingTwo = {
    dots: false,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 5000,
    arrows: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true,
  };
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  return (
    <section
      className={`testimonial-section-three pt-120 rpt-100 pb-240 containertyle6 ${
        className || ""
      }`}
    >
      <div className="container">
        <div className="testimonial-three-wrap bg-lighter containertyle7 ">
          <div className="row align-items-center ">
            <div className="col-md-5">
              <div className="testimonial-three-content text-center p-55 wow fadeInLeft delay-0-2s">
                <div className="section-title mb-25 ">
                  <span className=" text-black">
                    {dataTitleServicesDetails2}
                  </span>
                  <br />
                  <br />
                  <h3>{dataContentServicesDetails1}</h3>
                </div>
                <div className="testimonial-three-active">
                  <SliderCom
                    asNavFor={nav2}
                    selector={(slider1) => setNav1(slider1)}
                    settings={settings}
                  >
                    <div className="testimonial-three-item">
                      <p>{dataContentServicesDetails2}</p>
                      <br />
                      <br />
                      <div className="designation">
                        <h5>{dataTitleServicesDetails8}</h5>
                      </div>
                    </div>
                    <div className="testimonial-three-item">
                      <p>{dataContentServicesDetails3}</p>
                      <br />
                      <br />
                      <div className="designation">
                        <h5>{dataTitleServicesDetails9}</h5>
                      </div>
                    </div>
                    <div className="testimonial-three-item">
                      <p>{dataContentServicesDetails4}</p>
                      <br />
                      <br />
                      <div className="designation">
                        <h5>{dataTitleServicesDetails10}</h5>
                      </div>
                    </div>
                  </SliderCom>
                </div>
                <div className="testimonial-three-thumbs quomo-testimonial-three-thumbs">
                  <SliderCom
                    asNavFor={nav1}
                    selector={(slider1) => setNav2(slider1)}
                    settings={settingTwo}
                  >
                    <img
                      src={process.env.REACT_APP_API_SERVICESDETAILS1}
                      alt="Client Logo"
                    />
                    <img
                      src={process.env.REACT_APP_API_SERVICESDETAILS2}
                      alt="Client Logo"
                    />
                    <img
                      src={process.env.REACT_APP_API_SERVICESDETAILS3}
                      alt="Client Logo"
                    />
                  </SliderCom>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="testimonial-left-image wow fadeInRight delay-0-2s">
                <img
                  className="w-200"
                  src={process.env.REACT_APP_API_SERVICESDETAILS4}
                  alt="Testimonial"
                  width="500"
                  height="500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
