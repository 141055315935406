import React, { useEffect, useState } from "react";
import SliderCom from "../helpers/SliderCom";

export default function TestimonialSection() {
  const [dataTitleServicesDetails5, setData125] = useState("");
  const [dataSubTitleServicesDetails5, setData126] = useState("");
  const [dataSubContentServicesDetails5, setData127] = useState("");
  const [dataSubTitleServicesDetails6, setData128] = useState("");
  const [dataSubContentServicesDetails6, setData129] = useState("");
  const [dataSubTitleServicesDetails7, setData130] = useState("");
  const [dataSubContentServicesDetails7, setData131] = useState("");
  const [dataSubTitleServicesDetails8, setData132] = useState("");
  const [dataSubContentServicesDetails8, setData133] = useState("");
  const [dataSubTitleServicesDetails9, setData134] = useState("");
  const [dataSubContentServicesDetails9, setData135] = useState("");
  const [dataSubTitleServicesDetails10, setData136] = useState("");
  const [dataSubContentServicesDetails10, setData137] = useState("");

  useEffect(() => {
    consultThree();
  }, [localStorage.getItem("id_language")]);

  const consultThree = () => {
    fetch(
      `${
        process.env.REACT_APP_API
      }/ds-pro-services-details/${localStorage.getItem("id_language")}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData125(data.data.attributes.title_servicesdetails_5);
        setData126(data.data.attributes.subtitle_servicesdetails_5);
        setData127(data.data.attributes.subcontent_servicesdetails_5);
        setData128(data.data.attributes.subtitle_servicesdetails_6);
        setData129(data.data.attributes.subcontent_servicesdetails_6);
        setData130(data.data.attributes.subtitle_servicesdetails_7);
        setData131(data.data.attributes.subcontent_servicesdetails_7);
        setData132(data.data.attributes.subtitle_servicesdetails_8);
        setData133(data.data.attributes.subcontent_servicesdetails_8);
        setData134(data.data.attributes.subtitle_servicesdetails_9);
        setData135(data.data.attributes.subcontent_servicesdetails_9);
        setData136(data.data.attributes.subtitle_servicesdetails_10);
        setData137(data.data.attributes.subcontent_servicesdetails_10);
      });
  };

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <section className="testimonial-four pt-150 rpt-100 containertyle8">
      <div className="container text-white">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-9">
            <div className="section-title text-center mb-60">
              <h2>{dataTitleServicesDetails5}</h2>
            </div>
          </div>
        </div>
        <div className="testimonial-four-active">
          <SliderCom settings={settings}>
            <div className="testimonial-four-item">
              <div className="testimonial-four-content">
                <h4 style={{ textAlign: "center" }}>
                  {dataSubTitleServicesDetails5}
                </h4>
                <p className="line-clamp-10" style={{ textAlign: "center" }}>
                  {dataSubContentServicesDetails5}
                </p>
              </div>
              <img
                src={process.env.REACT_APP_API_SERVICESDETAILS6}
                alt="Client Logo"
                className="center"
              />
            </div>
            <div className="testimonial-four-item">
              <div className="testimonial-four-content">
                <h4 style={{ textAlign: "center" }}>
                  {dataSubTitleServicesDetails6}
                </h4>
                <p className="line-clamp-10" style={{ textAlign: "center" }}>
                  {dataSubContentServicesDetails6}
                </p>
              </div>
              <img
                src={process.env.REACT_APP_API_SERVICESDETAILS7}
                alt="Client Logo"
                className="center"
              />
            </div>
            <div className="testimonial-four-item">
              <div className="testimonial-four-content">
                <h4 style={{ textAlign: "center" }}>
                  {dataSubTitleServicesDetails7}
                </h4>
                <p className="line-clamp-10" style={{ textAlign: "center" }}>
                  {dataSubContentServicesDetails7}
                </p>
              </div>
              <img
                src={process.env.REACT_APP_API_SERVICESDETAILS8}
                alt="Client Logo"
                className="center"
              />
            </div>
            <div className="testimonial-four-item">
              <div className="testimonial-four-content">
                <h4 style={{ textAlign: "center" }}>
                  {dataSubTitleServicesDetails8}
                </h4>
                <p className="line-clamp-10" style={{ textAlign: "center" }}>
                  {dataSubContentServicesDetails8}
                </p>
              </div>
              <img
                src={process.env.REACT_APP_API_SERVICESDETAILS9}
                alt="Client Logo"
                className="center"
              />
            </div>
            <div className="testimonial-four-item">
              <div className="testimonial-four-content">
                <h4 style={{ textAlign: "center" }}>
                  {dataSubTitleServicesDetails9}
                </h4>
                <p className="line-clamp-10" style={{ textAlign: "center" }}>
                  {dataSubContentServicesDetails9}
                </p>
              </div>
              <img
                src={process.env.REACT_APP_API_SERVICESDETAILS10}
                alt="Client Logo"
                className="center"
              />
            </div>
            <div className="testimonial-four-item">
              <div className="testimonial-four-content">
                <h4 style={{ textAlign: "center" }}>
                  {dataSubTitleServicesDetails10}
                </h4>
                <p className="line-clamp-10" style={{ textAlign: "center" }}>
                  {dataSubContentServicesDetails10}
                </p>
              </div>
              <img
                src={process.env.REACT_APP_API_SERVICESDETAILS11}
                alt="Client Logo"
                className="center"
              />
            </div>
          </SliderCom>
        </div>
      </div>
    </section>
  );
}
