import React, { useEffect, useState } from "react";

// eslint-disable-next-line no-unused-vars
export default function Hero3() {
  const tab = <>&nbsp;&nbsp;</>;
  const [dataTitleServices1, setData] = useState("");
  const [dataTitleServices4, setData105] = useState("");
  const [dataTitleServices5, setData106] = useState("");

  useEffect(() => {
    consultThree();
  }, [localStorage.getItem("id_language")]);

  const consultThree = () => {
    fetch(
      `${process.env.REACT_APP_API}/ds-pro-services/${localStorage.getItem(
        "id_language"
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData(data.data.attributes.title_services_1);
        setData105(data.data.attributes.title_services_4);
        setData106(data.data.attributes.title_services_5);
        // setData3(data.data[0].attributes.contact);
      });
  };

  return (
    <section
      className="hero-section-two bgs-cover overlay pt-40 rpt-80 containertyle17"
      // eslint-disable-next-line no-useless-concat
      // style={{
      //   backgroundImage:
      //     // eslint-disable-next-line prefer-template
      //     `url(${process.env.REACT_APP_API_SERVICES_BACKGROUND})`,
      // }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-10 col-sm-11">
            <div className=" text-white py-150 rpy-150">
              <div className="testimonial-two-active">
                <div className="terms">
                  <h2 className="fontelectiontyle"> </h2>
                  <h3 className="wow fadeInUp delay-0-4s mt-20">
                    <a className=" fontelection fontsize" href="/">
                      {tab}
                      {dataTitleServices4}
                      {tab}
                    </a>
                  </h3>

                  <h3 className="wow fadeInUp delay-0-4s mt-20 fontelection ">
                    {" "}
                    /{" "}
                  </h3>
                  <h3 className="wow fadeInUp delay-0-4s mt-20">
                    <a className="fontelection fontsize" href="/service">
                      {tab}
                      {dataTitleServices5}
                    </a>
                  </h3>
                </div>
                <div className="testimonial-two-active">
                  <br /> <br />
                  <h2 className="wow fadeInUp delay-0-4s rml-1 marginstops fontelection2">
                    {dataTitleServices1}
                  </h2>
                </div>
                {/* <h1 className="wow fadeInUp delay-0-4s mt-30">
                  {dataTitleServices1}
                </h1> */}
              </div>
              {/* <div className="hero-btns mt-35 wow fadeInUp delay-0-6s">
                <a href="/contact" className="theme-btn mr-25">
                  {dataContact}
                </a>
                <a
                  onClick={(e) => videoHandler(e)}
                  href="#"
                  className="mfp-iframe video-play"
                >
                  <i className="fas fa-play"></i>
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
