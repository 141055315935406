import React, { useEffect, useState } from "react";

export default function OurTeamSection({ className }) {
  const [dataTeams, setDataTeams] = useState([]);

  useEffect(() => {
    consultFive();
  }, [localStorage.getItem("id_language")]);

  const consultFive = () => {
    fetch(
      `${process.env.REACT_APP_API}/ds-pro-team-langs?locale=${
        localStorage.getItem("id_language") === "1" ? "es-CR" : "en"
      }&populate=*`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setDataTeams(data.data);
      });
  };

  return (
    <>
      <section
        className={`team-section bg-lighter text-center pt-10 rpt-95 pb-90 rpb-70 containertyle11 ${
          className || ""
        }`}
      >
        <div className="container"></div>
        <div className="section-title text-center mb-35"></div>
        <div className="row3  text-white  center5">
          {dataTeams.length > 0
            ? dataTeams.map((item, index) => (
                <div key={index} className="col-lg-3 col-sm-6">
                  <div className="team-member-four wow fadeInUp delay-0-2s">
                    <div className="image">
                      <img
                        src={`https://api.preview.dspro.tech${item.attributes.img_team_url}`}
                        alt="Team Member"
                      />
                    </div>
                    <div className="member-designation hero-content5">
                      <h3>
                        <a href=" ">{item.attributes.team_name}</a>
                      </h3>
                      <span>{item.attributes.team_rol}</span>
                    </div>
                  </div>
                </div>
              ))
            : ""}
        </div>
      </section>
    </>
  );
}
