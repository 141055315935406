import React, { useEffect } from "react";
import { RiArrowUpSLine } from "react-icons/ri";
import BackToTop from "../../lib/BackToTop";

function BacktoTopCom() {
  useEffect(() => {
    BackToTop(".scroll-top");
  });
  return (
    // <button
    //   type="button"
    //   // className={`scroll-top scroll-to-target  ${className || ""}`}
    // >
    //   <RiArrowUpSLine className="center8"> </RiArrowUpSLine>
    // </button>
    <RiArrowUpSLine className="center9"> </RiArrowUpSLine>
  );
}

export default BacktoTopCom;
