import React from "react";
// import { blogs } from "../../data/blogs.json";
// import { teams } from "../../data/Teams.json";
// import BacktoTopCom from "../helpers/BackToTopCom";
// nimport NewsSection from "../HomeTwo/NewsSection";
// import FooterHomeTwo from "../partials/Footers/FooterHomeTwo/index";
import FooterHomeTwoWL from "../partials/FootersWhiteLabel/FooterHomeTwoWL/index";
import HeaderHomeThreeWL from "../partials/HeadersWhitelabel/HeaderHomeThreeWL";
// import AboutSection from "./AboutSection";
// import GallerySection from "./GallerySection";
// import GetInTouchSection from "./GetInTouchSection";
import Hero3WL from "./Hero3WL";
import Hero5WL from "./Hero5WL";
import Hero8WL from "./Hero8WL";
import HeroWL from "./HeroWL";
// import PricingSection from "./PricingSection";
import ContactwoWL from "./ContactwoWL";
import ServiceSectionWL from "./ServiceSectionWL";
// import SubscribeSection from "./SubscribeSection";

// import BlogSection from "../Blog/BlogSection";
import ServiceSectiontwo from "../HomeOne/ServiceSection";
import MainSectiontwoWL from "../PortfolioPages/PortfolioThreeWhiteLabel/MainSectiontwo";
import WorkProcessSectiontwo from "../ServicePages/ServiceOne/WorkProcessSectiontwo";
import WorkProcessSectionCodeWL from "./WorkProcessSectionCodeWL";
import WorkProcessSectionWL from "./WorkProcessSectionWL";

export default function HomeThree() {
  let stateMenu = true;
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    // console.log("Estás usando un dispositivo móvil!!");
    stateMenu = false;
  }
  return (
    <>
      <HeaderHomeThreeWL />
      {stateMenu === true ? (
        <>
          <HeroWL />
          <ServiceSectionWL />
          <WorkProcessSectionCodeWL />
        </>
      ) : (
        ""
      )}
      <WorkProcessSectionWL />
      {/* <Hero2 />
      <MainSection />
      <AboutSection
        className="pb-240 r-com-about-section-two"
        funFact={false}
      />
      <TestimonialSection />
      <GetInTouchSection /> */}

      {
        // <BlogSection className="pb-240 r-com-about-section-two" />
      }

      <Hero3WL />
      <ServiceSectiontwo />
      <WorkProcessSectiontwo />
      <Hero5WL />
      <MainSectiontwoWL />
      {/* <Hero7 />
      <OurTeamSection /> */}
      <Hero8WL />
      <ContactwoWL />
      <FooterHomeTwoWL className="footer-home-three pt-150" />
      {/* <BacktoTopCom /> */}
    </>
  );
}
