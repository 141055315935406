import React, { useEffect, useState } from "react";
import InnerPageDefaultLayoutAbout from "../../helpers/InnerPageDefaultLayoutAbout";
import AboutSection from "../../HomeOne/AboutSection";
import MainSection from "./MainSection";

export default function AboutSeven() {
  const [dataTitle6, setData23] = useState("");

  useEffect(() => {
    consultTwo();
  }, [localStorage.getItem("id_language")]);

  const consultTwo = () => {
    fetch(
      `${process.env.REACT_APP_API}/ds-pro-abouts/${localStorage.getItem(
        "id_language"
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData23(data.data.attributes.title_about_1);
        // setData3(data.data[0].attributes.contact);
      });
  };

  return (
    <InnerPageDefaultLayoutAbout
      pageTitle={dataTitle6}
      breadcrumbs={[
        { name: "Home", path: "/home" },
        { name: "About ", path: "/about" },
      ]}
    >
      <MainSection />
      <AboutSection
        className="pb-240 r-com-about-section-two"
        funFact={false}
      />
    </InnerPageDefaultLayoutAbout>
  );
}
