import React from "react";
import { useParams } from "react-router-dom";
import FooterHomeTwo from "../../partials/Footers/FooterHomeTwo";
import HeaderHomeThree from "../../partials/Headers/HeaderHomeThree";
import BlogSection from "../BlogSection";
import MainSection from "./MainSection";

const Blog = () => {
  const params = useParams();

  if (Object.entries(params).length > 0) {
    return (
      <>
        {
          // <HeaderHomeThree />
        }
        <MainSection />
        {/* <BlogSection className="pb-240 r-com-about-section-two" /> 
        <FooterHomeTwo className="footer-home-three pt-150" />*/}
        {/* <BacktoTopCom /> */}
      </>
    );
  }
  return (
    <>
      <HeaderHomeThree />
      <BlogSection className="pb-240 r-com-about-section-two" />

      <FooterHomeTwo className="footer-home-three pt-150" />
      {/* <BacktoTopCom /> */}
    </>
  );
};

export default Blog;
