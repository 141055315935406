import React from "react";
// import GetInTouchSection from "../AboutPages/AboutOne/GetInTouchSection";
import Hero3WL from "../HomeThreeWhiteLabel/Hero3WL";
// import TestimonialSection from "../HomeThree/TestimonialSection";
// import BacktoTopCom from "./BackToTopCom";
import LayoutsWhitelabel from "./LayoutsWhitelabel";

export default function InnerPageDefaultLayoutServices({ children }) {
  return (
    <LayoutsWhitelabel>
      <Hero3WL />
      {children && children}
      {/* <TestimonialSection />
      <GetInTouchSection />
      <BacktoTopCom /> */}
    </LayoutsWhitelabel>
  );
}
