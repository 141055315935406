import React, { useEffect, useState } from "react";
import logo2 from "../../../../assets/images/logos/logo-two.png";
// import logo from "../../../../assets/images/logos/logo.png";
import StickyMenu from "../../../../lib/StickyMenu";
import NavigationWL from "../../../helpers/NavigationWL";
import MobileHeaderComWL from "../../MobileWhiteLabel/MobileHeaderComWL";
/* eslint-disable */

export default function HeaderHomeThree() {
  useEffect(() => {
    StickyMenu(".main-header");
  });

  const [dataContact, setData3] = useState("");

  useEffect(() => {
    consultEight();
  }, [localStorage.getItem("id_language")]);

  const consultEight = () => {
    fetch(
      `${process.env.REACT_APP_API}/ds-pro-navbars/${localStorage.getItem(
        "id_language"
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData3(data.data.attributes.contact);
      });
  };

  return (
    <>
      <MobileHeaderComWL logo={logo2} />
      <header className="main-header header-three text-white">
        {/* <div className="header-top-wrap bg-blue py-10"> */}
        <div className="container">
          <div className="header-top">
            <div className="top-left">
              {/* <ul>
                  <li>
                    Call Us: <a href="callto:548978478">548978478</a>
                  </li>
                  <li>
                    Email us:
                    <a href="mailto:demo@example.com">demo@example.com</a>
                  </li>
                </ul> */}
            </div>
            {/* <div className="top-right">
                <div className="office-time">
                  <i className="far fa-clock"></i>
                  <span>08:00 am - 06:00 pm</span>
                </div>
              </div> */}
          </div>
        </div>
        {/* </div> */}
        <div className="header-upper">
          <div className="container clearfix">
            <div className="header-inner d-flex align-items-center">
              <div className="logo-outer">
                <div className="logo">
                  <a href="/whitelabel">
                    {/* <img src={logo} alt="Logo" title="Logo" /> */}
                    <img
                      alt="Qries"
                      src={process.env.REACT_APP_API_LAUNCH}
                      width="130"
                      height="73"
                      alt="Launch Logo"
                    ></img>
                  </a>
                </div>
              </div>

              <div className="nav-outer clearfix d-flex align-items-center">
                <div className="main-menu navbar-expand-lg">
                  <NavigationWL />
                </div>
                {/* <div className="menu-btn">
                  <a href="/contact" className="theme-btn2 fontelection">
                    {dataContact} <i aria-hidden="true"></i>
                  </a>
                </div> */}

                {/* <div className="wrap  ">
                  <a href="/contact">
                    <button href="/contact" className=" button3">
                      {dataContact}{" "}
                      <i className="fa fa-rocket" aria-hidden="true"></i>{" "}
                    </button>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
