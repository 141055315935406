import React, { useEffect, useState } from "react";
// import aboutThumb from "../../../assets/images/about/about-seven.png";

export default function MainSection() {
  const [dataTitleAbout, setData23] = useState("");
  const [dataTitleAbout2, setData24] = useState("");
  // const [dataContentAbout, setData25] = useState("");
  const [dataSubTitleAbout, setData26] = useState("");
  const [dataSubTitleAbout2, setData27] = useState("");
  const [dataSubTitleAbout3, setData28] = useState("");
  // const [dataSubTitleAbout4, setData29] = useState("");

  useEffect(() => {
    consultTwo();
  }, [localStorage.getItem("id_language")]);

  const consultTwo = () => {
    fetch(
      `${process.env.REACT_APP_API}/ds-pro-abouts/${localStorage.getItem(
        "id_language"
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData23(data.data.attributes.title_about_2);
        setData24(data.data.attributes.title_about_3);
        // setData25(data.data.attributes.content_about_1);
        setData26(data.data.attributes.subtitle_about_1);
        setData27(data.data.attributes.subtitle_about_2);
        setData28(data.data.attributes.subtitle_about_3);
        // setData29(data.data.attributes.subtitle_about_4);

        // setData3(data.data[0].attributes.contact);
      });
  };

  return (
    <section className="about-seven rel z-1 pt-150 rpt-30 pb-120 containertyle2 ">
      {/* // className="hero-section-two bgs-cover overlay pt-150 rpt-30 pb-120"
      // style={{
      //   backgroundImage:
      //     // eslint-disable-next-line prefer-template
      //     `url(${process.env.REACT_APP_API_ABOUT1})`,
      //   backgroundSize: 1500,
      // }} */}

      <div className="container ">
        <div className="row align-items-center">
          <div className="col-lg-7">
            <div className="about-seven-image rmb-60">
              <img
                className="imagestyle"
                src={process.env.REACT_APP_API_ABOUT2}
                alt="Client Logo"
                style={{
                  width: 500,
                }}
              />
            </div>
          </div>
          <div className="col-lg-5">
            <div className="about-seven-content text-white">
              <div className="section-title mb-30">
                <br />
                <br />
                <br />
                <h3 className="marginstops11">{dataTitleAbout}</h3>
                <div className="hero-content3">
                  {" "}
                  <h4 className="fontelection"> {dataTitleAbout2}</h4>
                </div>
              </div>
              {/* <p>{dataContentAbout}</p> */}
              <ul className="list-style-three mt-35 mb-50 ">
                <li className=" text-white fontelection fontsize">
                  <br />
                  <span className="fontelection mt-3">
                    {" "}
                    {dataSubTitleAbout}
                  </span>
                </li>
                <li className=" text-white fontelection fontsize">
                  <br />
                  <span className="fontelection mt-3">
                    {dataSubTitleAbout2}
                  </span>
                </li>
                <li className="  text-white fontelection fontsize ">
                  <span className="fontelection mt-3">
                    {" "}
                    {dataSubTitleAbout3}
                  </span>
                </li>
                {/* <li className="text-white">{dataSubTitleAbout4}</li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
