import React, { useEffect, useState } from "react";
import Background from "../../assets/images/counter/counter-bg.jpg";
import CounterUp from "../helpers/CounterUp";

function AboutSection({ className, funFact = true }) {
  const [dataTitleAbout4, setData30] = useState("");
  const [dataTitleAbout5, setData31] = useState("");
  // const [dataContentAbout2, setData32] = useState("");
  const [dataSubTitleAbout5, setData33] = useState("");
  const [dataSubTitleAbout6, setData34] = useState("");
  const [dataSubTitleAbout7, setData35] = useState("");
  const [dataSubTitleAbout8, setData36] = useState("");
  const [dataSubTitleAbout9, setData37] = useState("");
  const [dataSubTitleAbout10, setData38] = useState("");

  useEffect(() => {
    consultTwo();
  }, [localStorage.getItem("id_language")]);

  const consultTwo = () => {
    fetch(
      `${process.env.REACT_APP_API}/ds-pro-abouts/${localStorage.getItem(
        "id_language"
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData30(data.data.attributes.title_about_4);
        setData31(data.data.attributes.title_about_5);
        // setData32(data.data.attributes.content_about_2);
        setData33(data.data.attributes.subtitle_about_5);
        setData34(data.data.attributes.subtitle_about_6);
        setData35(data.data.attributes.subtitle_about_7);
        setData36(data.data.attributes.subtitle_about_8);
        setData37(data.data.attributes.subtitle_about_9);
        setData38(data.data.attributes.subtitle_about_10);
      });
  };

  return (
    <section
      className={`about-section bg-light-black pt-120 rpt-100 containertyle15  ${
        className || ""
      }`}
      id="about-section "
      // style={{
      //   backgroundImage:
      //     // eslint-disable-next-line prefer-template
      //     `url(${process.env.REACT_APP_API_ABOUT_BACKGROUND4})`,
      // }}
    >
      <div className="container">
        <div className="row align-items-center ">
          <div className="col-md-7">
            <div className="about-seven-content text-white">
              <div></div>
              <div className="section-title mb-30">
                <h3 className="marginstops11">{dataTitleAbout4}</h3>
                <div className="hero-content3">
                  <h4 className="fontelection">{dataTitleAbout5}</h4>
                </div>
              </div>
              {/* <p>{dataContentAbout2}</p> */}
              {/* <ul className="list-style-one mt-16 fontelection ">
                
              </ul>
              <ul className="list-style-one  fontelection ">
                
              </ul> */}
              <div className="row">
                <div className="col-6 marginstops6">
                  <ul className="list-style-three mt-35 mb-50 ">
                    <li className=" text-white fontelection fontsize">
                      <span className="fontelection mt-3">
                        {dataSubTitleAbout5}
                      </span>
                    </li>
                    <li className=" text-white fontelection fontsize">
                      <span className="fontelection mt-3">
                        {dataSubTitleAbout6}
                      </span>
                    </li>
                    <li className=" text-white fontelection fontsize">
                      <span className="fontelection mt-3">
                        {dataSubTitleAbout7}
                      </span>
                    </li>
                  </ul>
                </div>{" "}
                <div className="col-6 marginstops7">
                  <ul className="list-style-three mt-35 mb-50 ">
                    <li className=" text-white fontelection fontsize">
                      <span className="fontelection mt-3">
                        {dataSubTitleAbout8}
                      </span>
                    </li>
                    <li className=" text-white fontelection fontsize">
                      <span className="fontelection mt-3">
                        {dataSubTitleAbout9}
                      </span>
                    </li>
                    <li className=" text-white fontelection fontsize">
                      <span className="fontelection mt-3">
                        {dataSubTitleAbout10}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>{" "}
          <div> </div>
          <div className="about-seven-image rmb-60 col-lg-5">
            <br />
            <br />
            <div className="rectangle  rmb-62"></div>
            <img
              className="marginstops4 rounded"
              src={process.env.REACT_APP_API_ABOUT3}
              alt="About"
              style={{
                width: 380,
                height: 380,
              }}
            />
          </div>
        </div>

        {funFact && (
          <div
            className="fact-counter-inner br-5 px-25 pt-80 pb-30 text-white text-center"
            style={{ backgroundImage: `url(${Background})` }}
          >
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="success-item wow fadeInUp delay-0-2s">
                  <span
                    className="count-text"
                    data-speed="5000"
                    data-stop="520"
                  >
                    <CounterUp endValue={520} sectionSelect="about-section " />
                  </span>
                  <p>Projects Done</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="success-item wow fadeInUp delay-0-4s">
                  <span className="count-text plus">
                    <CounterUp endValue={24} sectionSelect="about-section " />
                  </span>
                  <p>Aon</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="success-item wow fadeInUp delay-0-6s">
                  <span
                    className="count-text plus"
                    data-speed="5000"
                    data-stop="12"
                  >
                    <CounterUp endValue={12} sectionSelect="about-section " />
                  </span>
                  <p>Years Experience</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="success-item wow fadeInUp delay-0-8s">
                  <span
                    className="count-text plus"
                    data-speed="5000"
                    data-stop="352"
                  >
                    <CounterUp endValue={352} sectionSelect="about-section " />
                  </span>
                  <p>Happy Clients</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default AboutSection;
