import emailjs from "@emailjs/browser";
import React, { useEffect, useRef, useState } from "react";
import { GoLocation } from "react-icons/go";
import swal from "sweetalert";

/* eslint-disable */
export default function FooterHomeTwo({ className }) {
  const tab = <>&nbsp;&nbsp;</>;
  const form = useRef();
  const [dataTitleFooter, setData221] = useState("");
  const [dataLocalizationFooter, setData222] = useState("");
  const [dataPagelinkFooter, setData223] = useState("");
  const [dataHomeFooter, setData224] = useState("");
  const [dataAboutFooter, setData225] = useState("");
  const [dataServicesFooter, setData226] = useState("");
  const [dataServicesdetailsFooter, setData227] = useState("");
  const [dataProjectsFooter, setData228] = useState("");
  const [dataProjectsdetailsFooter, setData229] = useState("");
  const [dataTeamFooter, setData230] = useState("");
  const [dataContactFooter, setData231] = useState("");
  const [dataExploreFooter, setData232] = useState("");
  // const [dataTitle10Footer, setData233] = useState("");
  // const [dataTitle11Footer, setData234] = useState("");
  // const [dataTitle12Footer, setData235] = useState("");
  // const [dataTitle13Footer, setData236] = useState("");
  // const [dataTitle14Footer, setData237] = useState("");
  // const [dataAdicionalInfoFooter, setData238] = useState("");
  const [dataInfoFooter, setData239] = useState("");
  const [dataEmailFooter, setData240] = useState("");
  const [dataCopyFooter, setData241] = useState("");
  const currentYear = new Date().getFullYear();
  const [dataRrssFooter, setData242] = useState("");
  const [dataRrssFooter2, setData243] = useState("");
  const [dataRrssFooter3, setData244] = useState("");
  const [dataRrssFooter4, setData245] = useState("");
  const [dataRrssFooter5, setData246] = useState("");
  const [dataRrssFooter6, setData247] = useState("");
  const [dataEmailSend, setData248] = useState("");
  const [dataTitle15Footer, setData249] = useState("");
  const [dataBannerFooter1, setData250] = useState("");
  const [dataBannerFooter2, setData251] = useState("");
  const [dataBannerFooter3, setData252] = useState("");
  const [dataBannerFooter4, setData253] = useState("");

  const sendEmail2 = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_EMAILJS,
        process.env.REACT_APP_TEMPLATE2_EMAILJS,
        form.current,
        process.env.REACT_APP_APIKEY_EMAILJS
      )
      .then(
        (result) => {
          swal({
            title: dataBannerFooter1,
            text: dataBannerFooter2,
            icon: "success",
            button: "OK",
          });
        },
        (error) => {
          swal({
            title: dataBannerFooter3,
            text: dataBannerFooter4,
            icon: "warning",
            button: "OK",
          });
        }
      );
  };

  useEffect(() => {
    consult();
  }, [localStorage.getItem("id_language")]);

  const consult = () => {
    fetch(
      `${process.env.REACT_APP_API}/ds-pro-footers/${localStorage.getItem(
        "id_language"
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData221(data.data.attributes.title_footer1);
        setData222(data.data.attributes.localization_footer);
        setData223(data.data.attributes.page_link);
        setData224(data.data.attributes.title_footer2);
        setData225(data.data.attributes.title_footer3);
        setData226(data.data.attributes.title_footer4);
        setData227(data.data.attributes.title_footer5);
        setData228(data.data.attributes.title_footer6);
        setData229(data.data.attributes.title_footer7);
        setData230(data.data.attributes.title_footer8);
        setData231(data.data.attributes.title_footer9);
        setData232(data.data.attributes.explore_footer);
        // setData233(data.data.attributes.title_footer10);
        // setData234(data.data.attributes.title_footer11);
        // setData235(data.data.attributes.title_footer12);
        // setData236(data.data.attributes.title_footer13);
        // setData237(data.data.attributes.title_footer14);
        // setData238(data.data.attributes.aditional_info);
        setData239(data.data.attributes.info_content);
        setData240(data.data.attributes.email_content);
        setData241(data.data.attributes.copy_rights);
        setData242(data.data.attributes.footer_rrss_1);
        setData243(data.data.attributes.footer_rrss_2);
        setData244(data.data.attributes.footer_rrss_3);
        setData245(data.data.attributes.footer_rrss_4);
        setData246(data.data.attributes.footer_rrss_5);
        setData247(data.data.attributes.footer_rrss_6);
        setData248(data.data.attributes.email_send);
        setData249(data.data.attributes.title_footer15);
        setData250(data.data.attributes.banner_footer_1);
        setData251(data.data.attributes.banner_footer_2);
        setData252(data.data.attributes.banner_footer_3);
        setData253(data.data.attributes.banner_footer_4);
      });
  };

  return (
    <footer
      className={` footer-two bgs-cover text-white containertyle13 ${
        className || ""
      }`}
      // style={{
      //   backgroundImage:
      //     // eslint-disable-next-line prefer-template
      //     `url(${process.env.REACT_APP_API_FOOTER_BACKGROUND})`,
      // }}
    >
      <div className="container">
        <div className="footer-top pt-10">
          {/* <ul className="contact-info">
            <li>
              <i className="fas fa-phone-alt"></i>
              <div className="content">
                <span>Call Us</span>
                <h5>
                  <a href="callto:+012-345-6789">+012-345-6789</a>
                </h5>
              </div>
            </li>
            <li>
              <i className="fas fa-envelope"></i>
              <div className="content">
                <span>Write to Us</span>
                <h5>
                  <a href="mailto:info@example.com">info@example.com</a>
                </h5>
              </div>
            </li>
            <li>
              <i className="fas fa-clock"></i>
              <div className="content">
                <span>Office hours</span>
                <h5>Mon-Sat 9:00 - 7:00</h5>
              </div>
            </li>
          </ul> */}
        </div>
        <div className="footer-widget-area pt-35 pb-0">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="footer-widget about-widget">
                <div className="footer-logo mb-35">
                  <a href="/">
                    <img
                      className="marginstops12"
                      src={process.env.REACT_APP_API_FOOTER1}
                      alt="Logo"
                      width="130"
                      height="73"
                    />
                  </a>
                </div>

                {/* <div className="text footerajust2">{dataTitleFooter}</div>
                <br />
                <br />
                <br />
                <ul className=" footerajust contact-info mt-20">
                  <li>
                    <h4 className="footerajust3 footertext">
                      {dataTitle15Footer}
                    </h4>{" "}
                  </li>

                  <li>
                    <GoLocation className="marginstops17"> </GoLocation>
                    <span className="footerajust5">
                      {" "}
                      {dataLocalizationFooter}
                    </span>
                  </li>
                </ul> */}
              </div>
            </div>
            <div className="col-lg-2 col-6">
              <div className="footer-widget link-widget ml-20 rml-0">
                <h4 className="footer-title footertext">
                  {dataPagelinkFooter}
                </h4>
                <ul className="list-style-two fontelection">
                  <li>
                    <a href="/">{dataHomeFooter}</a>
                  </li>
                  <li>
                    <a href="/about">{dataAboutFooter}</a>
                  </li>
                  <li>
                    <a href="/service">{dataServicesFooter}</a>
                  </li>
                  {/* <li>
                    <a href="/service-details">{dataServicesdetailsFooter}</a>
                  </li> */}
                  <li>
                    <a href="/portfolio">{dataProjectsFooter}</a>
                  </li>
                  {/* <li>
                    <a href="/portfolio-details">{dataProjectsdetailsFooter}</a>
                  </li> */}
                  <li>
                    <a href="/team">{dataTeamFooter}</a>
                  </li>
                  <li>
                    <a href="/contact">{dataContactFooter}</a>
                  </li>
                </ul>
                <br />
                <br />
                <h4 className="footer-title footertext">{dataTitle15Footer}</h4>
                <ul className="list-style-two fontelection">
                  <li>
                    <GoLocation className="marginstops17"> </GoLocation>
                    <span>{dataLocalizationFooter}</span>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-lg-3 col-6">
              <div className="footer-widget link-widget ml-20 rml-0">
                <h4 className="footer-title footertext">{dataExploreFooter}</h4>
                <ul className="list-style-two fontelection">
                  <li>
                    <a href="#">{dataTitle10Footer}</a>
                  </li>
                  <li>
                    <a href="#">{dataTitle11Footer}</a>
                  </li>
                  <li>
                    <a href="#">{dataTitle12Footer}</a>
                  </li>
                  <li>
                    <a href="#">{dataTitle13Footer} </a>
                  </li>
                  <li>
                    <a href="#">{dataTitle14Footer} </a>
                  </li>
                </ul>
              </div>
            </div> */}
            <div className="col-lg-4 col-sm-6">
              <div className="footer-widget newsletter-widget footerajust4 footerajust6">
                <h4 className="marginstops5">{dataInfoFooter}</h4>
                <form ref={form} onSubmit={sendEmail2}>
                  <input
                    className="fontelection14"
                    type="email"
                    name="email"
                    placeholder={dataEmailFooter}
                    required
                  />
                  {tab}

                  <button type="submit " value="Send">
                    <span className="fontelection"> {dataEmailSend}</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="copyright-inner pt-16">
            {/* <div className="social-style-one mb-10">
              <a href={dataRrssFooter}>
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href={dataRrssFooter2}>
                <i className="fab fa-instagram"></i>
              </a>
              <a href={dataRrssFooter3}>
                <i className="fab fa-twitter"></i>
              </a>
              <a href={dataRrssFooter4}>
                <i className="fab fa-linkedin"></i>
              </a>
              <a href={dataRrssFooter5}>
                <i className="fab fa-youtube"></i>
              </a>
              <a href={dataRrssFooter6}>
                <i className="fab fa-whatsapp"></i>
              </a>
            </div> */}
            <p>DSPro ©® {currentYear} Copyright All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
