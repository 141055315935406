import React, { useEffect, useState } from "react";
import SliderCom from "../helpers/SliderCom";

const BlogSection = (className) => {
  const [dataBlogs, setDataBlogs] = useState("");

  useEffect(() => {
    consult();
  }, [localStorage.getItem("id_language")]);

  const consult = () => {
    fetch(
      `${process.env.REACT_APP_API}/ds-pro-blogs?locale=${
        localStorage.getItem("id_language") === "1" ? "es-CR" : "en"
      }&populate=*`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setDataBlogs(data.data);
      });
  };
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="work-progress-three bgs-cover text-white pb-85 rpb-65 containertyle5">
      <div className="container">
        <div className="testimonial-wrap ">
          <div className="section-title text-center mb-65">
            <span className="sub-title"></span>
            <h2 className="text-white marginstops8 fontelection5">Blogs</h2>
          </div>
          <SliderCom settings={settings}>
            {dataBlogs.length > 0
              ? dataBlogs.map((item, index) => (
                  <a
                    key={index}
                    href={`/blog/${item.id}/${item.attributes.blog_url}`}
                  >
                    <div className="testimonial-item wow fadeInLeft delay-0-2s containertyle3">
                      <div className="author-description">
                        <img
                          src={`https://api.preview.dspro.tech${item.attributes.blog_img_url}`}
                          alt="Blog"
                        />
                        <div className="designation">
                          <h5 className="fontelection7">
                            {item.attributes.blog_title}
                          </h5>
                          <span className="fontelection6">
                            {item.attributes.ds_pro_blog_categories.data
                              .length > 0
                              ? item.attributes.ds_pro_blog_categories.data[0]
                                  .attributes.blog_android
                              : ""}
                          </span>
                        </div>
                        <i className="fas fa-quote-right"></i>
                      </div>
                      <hr className="lineup3" />
                      <p className="fontelection8">
                        {item.attributes.blog_description}
                      </p>
                      <h5 className="fontelection9">
                        {item.attributes.createdAt}
                      </h5>
                    </div>
                  </a>
                ))
              : ""}
          </SliderCom>
          <br />
          <br />
        </div>
      </div>
    </section>
  );
};

export default BlogSection;
